import React from "react";
import { Row, Col, Button, Form, Input, Modal, Divider, Dropdown, Menu, } from "antd";
import { DownOutlined } from "@ant-design/icons";
import AllPosts from "./Blogs/all-posts";
import { Link } from "react-router-dom";
import {
  fetchSingleStock,

} from "../redux/actions";
import { connect } from "react-redux";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      stock: props.stock,
      stock: [],
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {


    fetch("https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      const result = await res.json();
      this.setState({
        stock: result.allStock,
      });
    });

  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    const MSG = {
      to: [
        // "leads@megatheron.co.za",
        "ianels182@gmail.com",
        //"allan@megatheron.co.za",
        //"xander@megatheron.co.za",
      ],
      from: "info@mtgroup.co.za",
      //to: [  //"ianels182@gmail.com", "monique@moniqs-interiors.com"],
      subject: "New Newsletter Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Newsletter Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Newsletter Enquiry from <br /> Kia Mahikeng website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                         
                        
                             
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@Mega Theron Group</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,

        email: "",
      });
    });
  };
  render() {
    const duplicateCheck = [];
    return (
      <div>
        <footer id='wrap-footer' className='desktop-view'>
          <div
            style={{ margin: "auto", display: "inline-flex", width: "100vw", backgroundColor: "#05141F" }}
          >
            <Row
              style={{
                maxWidth: "100vw",
                margin: "auto",
                marginTop: "2em",
              }}
            >
              <Col span={1}></Col>
              <Col span={6}>

                <h1
                  style={{
                    textAlign: "left",
                    fontSize: "35px",
                    marginTop: "1em",
                    lineHeight: "1em",
                    color: "white"
                  }}
                >
                  Dare to be you.
                </h1>
                <p
                  style={{
                    textAlign: "left",
                    color: "#4F5665",
                    fontSize: "16px",
                    lineHeight: "1.2em", color: "white"
                  }}
                >
                  View the latest blogs from Kia Mahikeng
                </p>{" "}
                <br />
                <Link to='./blogs'>
                  <Button
                    style={{
                      fontFamily: "",
                      border: "1px solid white",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "0em",
                      backgroundColor: "white",
                      color: "#05141F",
                      height: "55px",
                      paddingTop: "0.2em",
                      paddingLeft: "3em",
                      paddingRight: "3em",
                      textShadow: "none",
                      margin: "auto",
                      marginTop: "2em",
                    }}
                    type='primary'
                    htmlType='submit'
                  >
                    View All Blogs
                  </Button>{" "}
                </Link>
              </Col>
              <Col span={1}></Col>
              <Col span={15}>
                <AllPosts />
              </Col>
            </Row>
          </div>{" "}
          <div style={{}}>
            <Row
              justify='center'
              style={{

                margin: "auto",
                paddingTop: "2em",
                paddingBottom: "3em",
              }}
            >
              <Col span={6}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/footer-car.png`}
                  alt='Logo'
                  style={{
                    display: "inline",


                  }}
                />
              </Col>
              <Col span={6}>
                <Link to={"/kia-range"}>
                  <Button
                    style={{
                      fontFamily: "",
                      border: "1px solid #F8F8F8",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "0em",
                      backgroundColor: "#F8F8F8",
                      color: "black",
                      height: "110px",
                      width: "95%",
                      textShadow: "none",
                      padding: "0.5em",
                      marginTop: "9em",

                    }}
                    type='primary'
                    htmlType='submit'
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/all-vehicles-wheel.svg`}
                      alt='logo'
                      style={{
                        width: "2.5em",
                        marginRight: "0.5em"

                      }}
                    />  Explore Range {"  "}<i style={{ marginLeft: "0.5em" }} class="fa fa-angle-right" aria-hidden="true"></i>
                  </Button>
                </Link>
              </Col>
              <Col span={6}>
                <Link to={"/Specials"}>
                  <Button
                    style={{
                      fontFamily: "",
                      border: "1px solid #F8F8F8",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "0em",
                      backgroundColor: "#F8F8F8",
                      color: "black",
                      height: "110px",
                      width: "95%",
                      textShadow: "none",
                      padding: "0.5em",
                      marginTop: "9em",
                    }}
                    type='primary'
                    htmlType='submit'
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/promotion-star.svg`}
                      alt='logo'
                      style={{
                        width: "2.5em",
                        marginRight: "0.5em"

                      }}
                    />  Promotions {"  "}<i style={{ marginLeft: "0.5em" }} class="fa fa-angle-right" aria-hidden="true"></i>
                  </Button>
                </Link>
              </Col>
              <Col span={6} style={{}}>
                <Link to={"/contactUs"}>
                  <Button
                    style={{
                      fontFamily: "",
                      border: "1px solid #F8F8F8",
                      fontSize: "15px",
                      fontWeight: 600,
                      borderRadius: "0em",
                      backgroundColor: "#F8F8F8",
                      color: "black",
                      height: "110px",
                      width: "95%",
                      textShadow: "none",
                      padding: "0.5em",
                      marginTop: "9em",
                    }}
                    type='primary'
                    htmlType='submit'
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/files-svg.svg`}
                      alt='logo'
                      style={{
                        width: "2.5em",
                        marginRight: "0.5em"

                      }}
                    /> Contact Us<i style={{ marginLeft: "0.5em" }} class="fa fa-angle-right" aria-hidden="true"></i>
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
          <iframe
            style={{
              filter: "grayscale(100%)",
              borderRadius: "0em",
              marginLeft: "-0em",
              // marginTop: "5em",
              width: "100vw",
            }
            }
            className='contact-hidden'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3590.814414409803!2d25.632931315022567!3d-25.84265998359997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ea2cb193ddc8297%3A0x794ce3a70c32eed9!2sKia%20Motors%20Mahikeng!5e0!3m2!1sen!2sza!4v1664541046330!5m2!1sen!2sza'
            height='300'
            title='map'
            frameBorder='0'
            allowFullScreen=''
            aria-hidden='false'
            tabIndex='0'
          ></iframe >

          <div className='footer-top' style={{ backgroundColor: "#05141F" }}>
            <div className='container-custom'>
              <div className='p-l-r'>
                <div className='row'>
                  <div className='col-xs-0 col-sm-1 col-md-1 m-t-lg-60'></div>
                  <div className='col-xs-12 col-sm-3 col-md-3 m-t-lg-60'>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/kia-white.png`}
                      alt='logo'
                      style={{
                        width: "6em",
                        marginBottom: "1.5em",
                        marginTop: "-1.5em",
                      }}
                    />
                    {/*<h3>Company Info</h3>*/}
                    <p style={{ fontSize: "13px", color: "white" }}>


                      Providing innovative products to keep South Africans moving and creating. There’s a product for each unique need.
                    </p>

                    <div className='row'>
                      <div className='col-md-12'>
                        <ul
                          className='social-icon list-inline '
                          style={{ marginTop: "0em" }}
                        >
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href='https://www.facebook.com/KiaMahikeng/'
                              target='_blank'
                            >
                              <i
                                style={{ color: "white", fontSize: "30px" }}
                                className='fa fa-facebook'
                              />
                            </a>
                          </li>
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href='https://twitter.com/Kia'
                              target='_blank'
                            >
                              <i
                                style={{ color: "white", fontSize: "30px" }}
                                className='fa fa-twitter'
                              />
                            </a>
                          </li>
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href='https://www.linkedin.com/company/kia-motors'
                              target='_blank'
                            >
                              <i
                                style={{ color: "white", fontSize: "30px" }}
                                className='fa fa-linkedin'
                              />
                            </a>
                          </li>
                        </ul>{" "}
                        <p
                          style={{
                            fontSize: "11px",
                            fontWeight: 200,
                            fontFamily: "",
                            lineHeight: "1.2em",
                            marginTop: "1.5em",
                            fontFamily: "",
                            color: "white",
                          }}
                        >
                          COPYRIGHT(C) 2022 KIA MAHIKENG. ALL RIGHTS RESERVED.
                        </p>{" "}
                      </div>{" "}
                    </div>
                  </div>
                  <div className='col-xs-0 col-sm-1 col-md-1 m-t-lg-60'></div>
                  <div className='col-xs-12 col-sm-2 col-md-2 m-t-lg-60'>
                    <h3 style={{ color: "white" }}>Legal</h3>

                    <ul className='list-footer-default' >
                      <li style={{ color: "white" }}>
                        <Link to='/legal-notice'>Legal Notice</Link>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to='/POPIA-manual'>PAIA & POPIA</Link>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to='/privacy-policy'>Privacy Statement</Link>
                      </li>{" "}
                      <li style={{ color: "white" }}>
                        <Link to='/terms'>Terms of Use</Link>
                      </li>
                    </ul>
                  </div>
                  <div className='col-xs-12 col-sm-2 col-md-2 m-t-lg-60'>
                    <h3 style={{ color: "white" }}>Services</h3>

                    <ul className='list-footer-default'>
                      <li style={{ color: "white" }}>
                        <Dropdown
                          bordered={false}
                          style={{

                            borderRadius: "0em",
                            height: 50,
                            color: "#06141f"
                          }}
                          //onChange={this.setCarEngineCapacity}
                          overlay={<Menu>

                            {this.state.stock.sort((a, b) => a.series.localeCompare(b.series))
                              .map((stock, index) => {
                                if (duplicateCheck.includes(stock.series)) return null;
                                duplicateCheck.push(stock.series);
                                return stock.webVisible && stock.megaDealers === "KIA" ? (
                                  <Link onClick={() => this.props.fetchSingleStock(stock)}
                                    to={`/stocko`}>
                                    <Menu.Item
                                      //style={{ textTransform: "uppercase" }}
                                      key={index}
                                      value={stock.series}
                                    >
                                      {stock.series}
                                    </Menu.Item></Link>
                                ) : null;
                              })}
                          </Menu>} >
                          <span >
                            New Models <DownOutlined />
                          </span>
                        </Dropdown>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to={"/stock"}>Used Models</Link>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to={"/Specials"}>Promotions</Link>
                      </li>


                    </ul>
                  </div>
                  <div className='col-xs-12 col-sm-2 col-md-2 m-t-lg-60'>
                    <h3 style={{ color: "white" }}>More</h3>

                    <ul className='list-footer-default'>
                      {/*<li>
                      <a>Brands</a>
                    </li>*/}
                      <li style={{ color: "white" }}>
                        <Link to={"/contactUs"}>Contact Us</Link>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to={"/blogs"}>Blog</Link>
                      </li>
                      <li style={{ color: "white", marginTop: "1em" }}>
                        <span >In Affiliation with</span>
                        <a href="https://www.megatheron.co.za/" target="_blank">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/logo-total-white.png`}
                            alt='logo'
                            style={{
                              width: "15em",
                              marginBottom: "1.5em",
                              marginTop: "-1em",

                            }}
                          />
                        </a>
                      </li>
                      {/*<li>
                      <a>FAQ</a>
                    </li>*/}
                    </ul>
                  </div>{" "}

                </div >          <Row><Col span={24} style={{ textAlign: "left", paddingTop: "2em", maxWidth: "110em", margin: "auto" }}>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: 200,

                      lineHeight: "1em",
                      marginTop: "0.5em",
                      color: "white",
                      margin: "auto",
                      marginBottom: "1em",
                      borderTop: "1px solid white", paddingTop: "1em"
                    }}
                  >
                    DISCLAIMER:<br />
                    Kia Mahikeng and / or its directors, employees,representatives, agents and affiliates ("Kia") do not guarantee the accuracy of information and / or accept responsibility for any inadvertent and obvious errors or omissions in vehicle prices or details reflected and do not accept any liability for any losses or damages that may be incurred including direct, indirect, special, incidental or consequential or for any inconvenience that may be experienced or on any incorrect information that may inadvertently have been displayed on this website. Kia reserves the right to make improvements and/or changes and amendments to the content contained on this website at any time without any notice. Every effort is made to ensure information loaded is accurate and updated regularly, however errors, technical and or other inaccuracies and typographical errors may occur from time to time. Vehicle pricing and extras may be subject to change without prior notification and exclude license, registration, documentation and / or delivery fees. No two vehicles are identical. This information therefore should be considered indicative rather than definitive. Kia cannot guarantee the availability of vehicle/s advertised due to the number of enquiries received.
                  </p>{" "}</Col></Row>
              </div >
            </div > {" "}

          </div >
        </footer > {" "}
        < footer id='wrap-footer' className='mobile-view' >
          <div style={{ margin: "auto", textAlign: "center" }}>
            <h6
              style={{
                textAlign: "center",
                margin: "auto",
                marginTop: "1em",
              }}
            >
              Stay up to date on the latest blogs
            </h6>
            <h1
              style={{
                textAlign: "center",
                fontSize: "35px",
                lineHeight: "0.2em",
              }}
            >
              Blogs & Reviews
            </h1>
            <p
              style={{
                textAlign: "center",
                color: "#4F5665",
                fontSize: "16px",
                lineHeight: "1.2em",
                padding: "1em",
                paddingBottom: "0em",
              }}
            >
              All things motoring – from reviews and helpful articles to news
              and special offers.
            </p>{" "}
            <br />
            <Link to='./blogs'>
              <Button
                style={{
                  fontFamily: "",
                  border: "1px solid #05141f",
                  fontSize: "15px",
                  fontWeight: 600,
                  borderRadius: "0em",
                  backgroundColor: "#05141f",
                  color: "white",
                  height: "55px",
                  paddingTop: "0.2em",
                  paddingLeft: "3em",
                  paddingRight: "3em",
                  textShadow: "none",
                  margin: "auto",
                  marginTop: "1em",
                  marginBottom: "2em",
                }}
                type='primary'
                htmlType='submit'
              >
                View All Blogs
              </Button>{" "}
            </Link>
            <Col span={24}>
              <AllPosts />
            </Col>
          </div>
          <iframe
            style={{
              filter: "grayscale(100%)",
              borderRadius: "0em",
              marginLeft: "-0em",
              // marginTop: "5em",
              width: "100vw",
            }
            }
            className='contact-hidden'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3590.814414409803!2d25.632931315022567!3d-25.84265998359997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ea2cb193ddc8297%3A0x794ce3a70c32eed9!2sKia%20Motors%20Mahikeng!5e0!3m2!1sen!2sza!4v1664541046330!5m2!1sen!2sza'
            height='300'
            title='map'
            frameBorder='0'
            allowFullScreen=''
            aria-hidden='false'
            tabIndex='0'
          ></iframe >
          <div className='footer-top' style={{ backgroundColor: "#05141F", marginTop: "-0.5em" }}>
            <div className='container-custom'>
              <div className='p-l-r'>
                <div className='row'>
                  <div className='col-xs-0 col-sm-1 col-md-1 m-t-lg-30'></div>
                  <div className='col-xs-12 col-sm-3 col-md-3 m-t-lg-30'>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/kia-white.png`}
                      alt='logo'
                      style={{
                        width: "6em",
                        marginBottom: "1.5em",
                        marginTop: "-1.5em",
                      }}
                    />
                    {/*<h3>Company Info</h3>*/}
                    <p style={{ fontSize: "13px", color: "white" }}>


                      Providing innovative products to keep South Africans moving and creating. There’s a product for each unique need.
                    </p>

                    <div className='row'>
                      <div className='col-md-12' style={{ paddingLeft: 0 }}>
                        <ul
                          className='social-icon list-inline '
                          style={{ marginTop: "0em" }}
                        >
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href='https://www.facebook.com/KiaMahikeng/'
                              target='_blank'
                            >
                              <i
                                style={{ color: "white", fontSize: "30px" }}
                                className='fa fa-facebook'
                              />
                            </a>
                          </li>
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href='https://twitter.com/Kia'
                              target='_blank'
                            >
                              <i
                                style={{ color: "white", fontSize: "30px" }}
                                className='fa fa-twitter'
                              />
                            </a>
                          </li>
                          <li style={{ marginRight: "2em" }}>
                            <a
                              href='https://www.linkedin.com/company/kia-motors'
                              target='_blank'
                            >
                              <i
                                style={{ color: "white", fontSize: "30px" }}
                                className='fa fa-linkedin'
                              />
                            </a>
                          </li>
                        </ul>

                      </div>

                    </div>
                  </div>
                  <div className='col-xs-0 col-sm-1 col-md-1 m-t-lg-10'></div>
                  <div className='col-xs-12 col-sm-2 col-md-2 m-t-lg-10'>
                    <p
                      style={{
                        fontSize: "11px",

                        marginTop: "1.5em",
                        fontFamily: "",
                        color: "white",
                      }}
                    >
                      COPYRIGHT(C) 2022 KIA MAHIKENG. ALL RIGHTS RESERVED.
                    </p>
                    <h3 style={{ color: "white" }}>Legal</h3>

                    <ul className='list-footer-default' >
                      <li style={{ color: "white" }}>
                        <Link to='/legal-notice'>Legal Notice</Link>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to='/POPIA-manual'>PAIA & POPIA</Link>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to='/privacy-policy'>Privacy Statement</Link>
                      </li>{" "}
                      <li style={{ color: "white" }}>
                        <Link to='/terms'>Terms of Use</Link>
                      </li>
                    </ul>
                  </div>
                  <div className='col-xs-12 col-sm-2 col-md-2 m-t-lg-10'>
                    <h3 style={{ color: "white" }}>Services</h3>

                    <ul className='list-footer-default'>
                      {/* <li style={{ color: "white" }}>
                        <Dropdown
                          bordered={false}
                          style={{

                            borderRadius: "0em",
                            height: 50,
                            color: "#06141f"
                          }}
                          //onChange={this.setCarEngineCapacity}
                          overlay={<Menu>

                            {this.state.stock.sort((a, b) => a.series.localeCompare(b.series))
                              .map((stock, index) => {
                                if (duplicateCheck.includes(stock.series)) return null;
                                duplicateCheck.push(stock.series);
                                return stock.webVisible && stock.megaDealers === "KIA" ? (
                                  <Link onClick={() => this.props.fetchSingleStock(stock)}
                                    to={`/stocko`}>
                                    <Menu.Item
                                      //style={{ textTransform: "uppercase" }}
                                      key={index}
                                      value={stock.series}
                                    >
                                      {stock.series}
                                    </Menu.Item></Link>
                                ) : null;
                              })}
                          </Menu>} >
                          <span >
                            New Models <DownOutlined />
                          </span>
                        </Dropdown>
                      </li> */}
                      <li style={{ color: "white" }}>
                        <Link to={"/stock"}>Used Models</Link>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to={"/Specials"}>Promotions</Link>
                      </li>


                    </ul>
                  </div>
                  <div className='col-xs-12 col-sm-2 col-md-2 m-t-lg-10'>
                    <h3 style={{ color: "white" }}>More</h3>

                    <ul className='list-footer-default'>
                      {/*<li>
                      <a>Brands</a>
                    </li>*/}
                      <li style={{ color: "white" }}>
                        <Link to={"/contactUs"}>Contact Us</Link>
                      </li>
                      <li style={{ color: "white" }}>
                        <Link to={"/blogs"}>Blog</Link>
                      </li>
                      <li style={{ color: "white", marginTop: "1em" }}>
                        <span >In Affiliation with</span>
                        <br />
                        <a href="https://www.megatheron.co.za/" target="_blank">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/logo-total-white.png`}
                            alt='logo'
                            style={{
                              width: "15em",
                              marginBottom: "1.5em",
                              marginTop: "-1em",

                            }}
                          />
                        </a>
                      </li>
                      {/*<li>
                      <a>FAQ</a>
                    </li>*/}
                    </ul>
                  </div>{" "}

                </div >          <Row><Col span={24} style={{ textAlign: "left", paddingTop: "2em", maxWidth: "110em", margin: "auto" }}>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: 200,

                      lineHeight: "1em",
                      marginTop: "0.5em",
                      color: "white",
                      margin: "auto",
                      marginBottom: "1em",
                      borderTop: "1px solid white", paddingTop: "1em"
                    }}
                  >
                    DISCLAIMER:<br />
                    Kia Mahikeng and / or its directors, employees,representatives, agents and affiliates ("Kia") do not guarantee the accuracy of information and / or accept responsibility for any inadvertent and obvious errors or omissions in vehicle prices or details reflected and do not accept any liability for any losses or damages that may be incurred including direct, indirect, special, incidental or consequential or for any inconvenience that may be experienced or on any incorrect information that may inadvertently have been displayed on this website. Kia reserves the right to make improvements and/or changes and amendments to the content contained on this website at any time without any notice. Every effort is made to ensure information loaded is accurate and updated regularly, however errors, technical and or other inaccuracies and typographical errors may occur from time to time. Vehicle pricing and extras may be subject to change without prior notification and exclude license, registration, documentation and / or delivery fees. No two vehicles are identical. This information therefore should be considered indicative rather than definitive. Kia cannot guarantee the availability of vehicle/s advertised due to the number of enquiries received.
                  </p>{" "}</Col></Row>
              </div >
            </div > {" "}

          </div >

        </footer > {" "}
        < Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt='Logo'
            style={{ width: "65%", margin: "auto" }}
          />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for subscribing to the
            <br />
            <span style={{ color: "rgb(6, 20, 31)" }}>
              Kia Mahikeng Newsletter.
            </span>{" "}
            <br />
            <br />
            <span style={{ color: "black ", fontSize: "17px" }}>
              We'll keep you updated on all our exclusive offerings.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
        </Modal >
      </div >
    );
  }
}
function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,

})(Footer);


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "./client";
import { Col, Row, Button } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);
  const [sliderRef, setSliderRef] = useState(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,

        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      },
      "name": author->name,
    }`
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
  }, []);

  return (
    <div className='content'>
      <div className='desktop-view'>
        <Slider ref={setSliderRef} {...settings}>
          {allPostsData &&
            allPostsData.map((post, index) => (
              <Col
                style={{
                  width: "22em",
                  padding: "0.5em",
                  height: "25em",
                  marginTop: "1em",
                }}
                key={index}
              >
                <div
                  style={{
                    width: "22em", height: "25em",
                    // background:
                    //   "linear-gradient(180deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 1) 100%)",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      width: "22em",
                      height: "15em",
                      zIndex: -9999,
                      borderRadius: "0em",
                      opacity: 0.5,
                    }}
                    src={post.mainImage.asset.url}
                    alt=''
                  />{" "}
                </div>
                <h2
                  className='main-blogs-header'
                  style={{
                    fontSize: "13px",
                    fontWeight: 700,
                    padding: "3em",
                    paddingTop: "2.3em",
                    paddingLeft: "0.5em",
                    margin: "auto",
                    maxWidth: "100%",
                    position: "absolute",
                    paddingTop: "2em",
                    top: "0em",
                    color: "white",
                    //background:
                    //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                    marginTop: "15em",
                    fontFamily: "",
                    lineHeight: "1em",
                  }}
                >
                  {post.name}
                </h2>
                <p
                  className='main-blogs-header'
                  style={{
                    fontSize: "15px",
                    fontWeight: 400,
                    padding: "3em",
                    paddingLeft: "0.5em",
                    margin: "auto",
                    maxWidth: "100%",
                    position: "absolute",
                    paddingTop: "2em",
                    top: "0em",
                    color: "white",
                    //background:
                    //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                    marginTop: "14em",

                    lineHeight: "1.3em",
                    // backgroundColor: "rgb(5, 20, 31)"
                  }}
                >
                  {post.title}
                </p>
                <Link to={"/" + post.slug.current} key={post.slug.current}>
                  <p
                    className='main-blogs-header'
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      padding: "2em",
                      left: 0,
                      paddingLeft: "0.5em",
                      margin: "auto",
                      maxWidth: "100%",
                      position: "absolute",

                      top: "8em",
                      color: "white",
                      //background:
                      //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                      marginTop: "11.5em",

                      lineHeight: "1em",
                    }}
                  >
                    Read more <SwapRightOutlined />
                  </p>
                </Link>
              </Col>
            ))}{" "}
        </Slider>
      </div>
      <div className='mobile-view'>
        <Slider ref={setSliderRef} {...settings}>
          {allPostsData &&
            allPostsData.map((post, index) => (
              <Col
                style={{
                  width: "22em",
                  padding: "0.5em",
                  height: "25em",
                  marginTop: "1em",
                }}
                key={index}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 1) 100%)",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      //width: "22em",
                      height: "25em",
                      zIndex: -9999,
                      borderRadius: "0em",
                      opacity: 0.5,
                    }}
                    src={post.mainImage.asset.url}
                    alt=''
                  />{" "}
                </div>
                <h2
                  className='main-blogs-header'
                  style={{
                    fontSize: "13px",
                    fontWeight: 700,
                    padding: "3em",
                    paddingTop: "2.3em",
                    paddingLeft: "0.5em",
                    margin: "auto",
                    maxWidth: "100%",
                    position: "absolute",
                    paddingTop: "2em",
                    top: "0em",
                    color: "white",
                    textAlign: "left",
                    //background:
                    //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                    marginTop: "11.5em",
                    fontFamily: "",
                    lineHeight: "1em",
                  }}
                >
                  {post.name}
                </h2>
                <p
                  className='main-blogs-header'
                  style={{
                    fontSize: "15px",
                    fontWeight: 500,
                    padding: "3em",
                    paddingLeft: "0.5em",
                    textAlign: "left",
                    margin: "auto",
                    maxWidth: "100%",
                    position: "absolute",
                    paddingTop: "2.5em",
                    top: "0em",
                    color: "white",
                    //background:
                    //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                    marginTop: "11.5em",
                    fontFamily: "",
                    lineHeight: "1.3em",
                  }}
                >
                  {post.title}
                </p>
                <Link to={"/" + post.slug.current} key={post.slug.current}>
                  <p
                    className='main-blogs-header'
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      padding: "2em",
                      right: 0,
                      paddingLeft: "0.5em",
                      margin: "auto",
                      maxWidth: "100%",
                      position: "absolute",

                      top: "8em",
                      color: "white",
                      //background:
                      //  "linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%)",
                      marginTop: "11.5em",
                      fontFamily: "",
                      lineHeight: "1em",
                    }}
                  >
                    Read more <SwapRightOutlined />
                  </p>
                </Link>
              </Col>
            ))}{" "}
        </Slider>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "./client";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import Header from "../Header";
import Footer from "../Footer";

import { Layout, Card, Button, Select, Col, Row, Divider } from "antd";
import { Link } from "react-router-dom";
const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function OnePost() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();
  document.documentElement.scrollTop = 0;
  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
             }
           },
         body,
        "name": author->name,
        "authorImage": author->image
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div style={{ color: "transparent" }}>Loading...</div>;

  return (
    <div
      style={{
        // display: postData.name === "Mega Theron Group" ? "inline" : "none",
      }}
    >
      {" "}
      <img
        style={{
          margin: "auto",
          zIndex: 0,
          left: 0,
          top: "0em",
          zIndex: -1,
          paddingTop: "3em",
          right: 0,
          opacity: 0.1,
          position: "absolute",
          width: "80vw",
          height: "90vh",
        }}
        src={urlFor(postData.mainImage).url()}
        alt=''
      />{" "}
      <div
        style={{
          position: "absolute",
          margin: "auto",
          left: 0,
          top: "0em",
          zIndex: -1,
          right: 0,
          boxShadow: "inset 0 0 100px 150px white",
          position: "absolute",
          width: "80vw",
          height: "90vh",
        }}
      ></div>{" "}
      <div
        id='home'
        className='home-div'
        style={{
          //maxHeight: "100em",
          marginTop: "0em",
          //width: "100vw",
        }}
      >
        {" "}
        <Row
          className='home-row'
          style={{
            maxWidth: "90em",
            margin: "auto",
          }}
        >
          <Col
            sm={24}
            md={24}
            style={{
              margin: "auto",
              textAlign: "center",
              marginTop: "13em",
              marginBottom: "3em",
            }}
          >
            <img
              style={{}}
              src={urlFor(postData.authorImage).width(250).url()}
              alt=''
            />
            <h2
              style={{
                marginTop: "0.5em",
                color: "#3F3F3F",
                fontWeight: 700,
                fontSize: "30px",
                lineHeight: "1em",
              }}
            >
              {postData.title}
            </h2>
            {/*<div>
              <h3 style={{ marginTop: "-1.5em", maxWidth: "90em" }}>
                {postData.name}
              </h3>
            </div>*/}
            <Col
              sm={24}
              md={24}
              style={{
                margin: "auto",
                textAlign: "center",
                marginTop: "-2em",
                marginBottom: "1em",
              }}
            ></Col>
            <div
              style={{
                margin: "auto",
                fontWeight: 100,
                color: "black",
                fontWeight: 400,
                fontSize: "17px",
                maxWidth: "90em",
                padding: "30px",
              }}
            >
              <BlockContent
                blocks={postData.body}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
              />
            </div>
            <Divider
              orientation='right'
              style={{ borderTopColor: "rgba(0, 0, 0, 0.25)" }}
            >
              <Link to='./blogs'>
                <Button
                  style={{
                    float: "right",
                    height: "2.7em",
                    width: "10em",
                    fontSize: "17px",
                    fontWeight: 500,
                    // borderRadius: "3em",
                    color: "white",
                    border: "none",
                    marginTop: "1em",
                    marginBottom: "1em",
                    backgroundColor: "black",
                    textShadow: "none",
                    boxShadow: "none",
                  }}
                >
                  More Blogs
                </Button>
              </Link>
            </Divider>
          </Col>
        </Row>{" "}
      </div>{" "}
    </div>
  );
}

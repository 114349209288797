import React, { Component } from "react";
import { Row, Col, Divider, Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../../redux/actions";
import CurrencyFormat from "react-currency-format";

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
class StockItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
    };
  }
  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }
  componentDidMount() {
    var stock = this.props.stock;

    if (
      this.props.wishlistproducts.filter(function (e) {
        return e._id === stock._id;
      }).length === 1
    ) {
      this.setState({ isInWishList: true });
    }
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render() {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "#05141f",
    //    fontWeight: "bold",
    //  };
    //}

    const { stock } = this.props;
    return (
      //<Fade>
      stock.webVisible && stock.megaDealers === "KIA" ? (
        <div
          className="col-sm-4 col-md-4 col-lg-4"
          style={{ padding: window.isPhone ? 0 : "" }}
        >
          <div
            style={{
              margin: "0.5em",
              backgroundColor: "white",
              padding: "15px",
              paddingBottom: "50px",
            }}
          >
            <div
              className="product-item"
              style={{ margin: "auto", marginBottom: "1em" }}
            >
              {/* <i
                id={JSON.stringify(stock)}
                //className={
                //  this.state.isInWishList ? "fa fa-heart" : "fa fa-heart-o"
                //}
                className={"fa fa-heart"}
                style={{
                  background: "#ecececbe",
                  borderRadius: "0em 0em 0em 0.8em",
                  padding: "1em",
                  position: "absolute",
                  right: 0,
                  top: 0,
                  color: this.state.isInWishList ? "red" : "grey",
                }}
                onClick={this.ToggleCarWishlist.bind(this)}
              /> */}
              {stock.onSale === true ? (
                <span
                  style={{
                    background: "rgb(205, 41, 52)",
                    borderRadius: "5em",
                    padding: "0.7em",
                    paddingTop: "1.1em",
                    paddingBottom: "1em",
                    position: "absolute",
                    right: 30,
                    top: 15,
                    color: "white",
                    fontSize: 11,
                  }}
                >
                  PROMO
                </span>
              ) : null}
              {stock.condition ? (
                <span
                  style={{
                    background:
                      stock.condition === "New"
                        ? "rgb(5, 20, 31)"
                        : stock.condition === "Demo"
                        ? "rgb(5, 20, 31)"
                        : "rgb(5, 20, 31)",
                    borderRadius: "0em",
                    padding: "0.3em",
                    paddingRight: "0.8em",
                    paddingLeft: "0.8em",
                    position: "absolute",
                    // right: 10,
                    // top: 158,
                    width: "4.3em",
                    color: "white",
                  }}
                >
                  {stock.condition.toUpperCase()}
                </span>
              ) : null}
              {/* {stock.modelYear ? (
              <span
                style={{
                  background: "rgba(0, 0, 0, 0.529)",
                  borderRadius: "0em",
                  padding: "0.3em",
                  paddingRight: "0.8em",
                  paddingLeft: "0.8em",
                  position: "absolute",
                  right: stock.condition ? 75 : 10,
                  top: 158,
                  color: "white",
                }}
              >
                {stock.modelYear}
              </span>
            ) : null} */}
              <Link
                className="product-img"
                onClick={() => this.props.fetchSingleStock(stock)}
                to={`/stock/vehicle-details`}
              >
                {stock.frontLeftImage ? (
                  <img
                    src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.frontLeftImage}`}
                    alt=""
                  />
                ) : (
                  <img
                    src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                    alt=""
                  />
                )}
              </Link>
              <div className="product-caption">
                <h4
                  className="product-name"
                  style={{
                    marginBottom: "0em",
                    marginTop: "0em",
                    paddingLeft: 0,
                    textTransform: "uppercase",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <Link
                    onClick={() => this.props.fetchSingleStock(stock)}
                    to={`/stock/vehicle-details`}
                  >
                    <span
                      className="f-20"
                      style={{
                        display: "block",
                        color: "#05141f",
                      }}
                    >
                      <span
                        className="f-20"
                        style={{
                          display: "block",
                          color: "#05141f",
                          whiteSpace: "nowrap",
                          width: "70%",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {stock.modelYear ? stock.modelYear : null} {stock.make}{" "}
                        {stock.series}
                      </span>
                      <span
                        className="f-20"
                        style={{
                          color: "#05141f",
                          float: "right",
                          marginTop: "-1.5em",
                          textAlign: "right",
                        }}
                      >
                        <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        />
                      </span>
                      <p
                        className="f-14"
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          paddingRight: "3em",
                          overflow: "hidden",
                          width: "21em",
                        }}
                      >
                        {stock.modelDesc}
                      </p>
                    </span>
                  </Link>
                </h4>{" "}
              </div>
            </div>
            {/* <Row
              style={{
                width: "100%",
                margin: "auto",
                marginTop: "5.5em",
                paddingTop: "1em",
              }}
            >
              <Col span={11}>
                <span
                  className="f-12"
                  style={{
                    fontWeight: 400,
                    color: "grey",
                  }}
                >
                  Mileage
                </span>
                <span
                  className="f-12"
                  style={{ float: "right", fontWeight: 600, color: "#05141f" }}
                >
                  {stock.mileage}
                </span>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <span
                  className="f-12"
                  style={{
                    fontWeight: 400,
                    color: "grey",
                  }}
                >
                  Fuel Type
                </span>
                <span
                  className="f-12"
                  style={{ float: "right", fontWeight: 600, color: "#05141f" }}
                >
                  {stock.fuelType}
                </span>
              </Col>
            </Row> */}
            {/* <Row style={{ width: "100%", margin: "auto", marginTop: "0.5em" }}>
              <Col span={11}>
                <span
                  className="f-12"
                  style={{
                    fontWeight: 400,
                    color: "grey",
                  }}
                >
                  Transmission
                </span>
                <span
                  className="f-12"
                  style={{
                    float: "right",
                    fontWeight: 600,
                    color: "#05141f",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {stock.transmission}
                </span>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <span
                  className="f-12"
                  style={{
                    fontWeight: 400,
                    color: "grey",
                  }}
                >
                  Body Type
                </span>
                <span
                  className="f-12"
                  style={{
                    float: "right",
                    fontWeight: 600,
                    color: "#05141f",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {stock.bodyType}
                </span>
              </Col>
            </Row> */}
            <Row style={{ width: "100%", margin: "auto", marginTop: "0.5em" }}>
              {/* <Col span={11}>
                <span
                  className="f-12"
                  style={{
                    fontWeight: 400,
                    color: "grey",
                  }}
                >
                  Colour
                </span>
                <span
                  className="f-12"
                  style={{
                    float: "right",
                    fontWeight: 600,
                    color: "#05141f",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {stock.extColour}
                </span>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <span
                  className="f-12"
                  style={{
                    fontWeight: 400,
                    color: "grey",
                  }}
                >
                  Int. Colour
                </span>
                <span
                  className="f-12"
                  style={{
                    float: "right",
                    fontWeight: 600,
                    color: "#05141f",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {stock.intColour}
                </span>
              </Col> */}
            </Row>
            <ul
              className="static-caption m-t-lg-20"
              style={{
                // borderTop: "1px solid lightgrey",
                margin: "auto",
              }}
            >
              <li>
                <i style={{}} class="fa fa-map-marker" aria-hidden="true"></i>
                {stock.brand} {stock.branch}
              </li>
              <li>
                {/* <i className='fa fa-tachometer'></i>
                  {stock.transmission} */}
              </li>
              <li>
                <Link
                  className="product-img"
                  onClick={() => this.props.fetchSingleStock(stock)}
                  to={`/stock/vehicle-details`}
                >
                  <Button
                    style={{
                      fontFamily: "",
                      border: "0px solid #05141f",
                      fontSize: "12px",
                      fontWeight: 600,
                      borderRadius: "3em",
                      backgroundColor: "#05141f",
                      color: "white",
                      paddingLeft: "1em",
                      // paddingRight: "3em",
                      height: "25px",
                      textShadow: "none",
                      margin: "auto",
                      float: "right",
                      display: "flex",
                      paddingRight: 0,
                    }}
                    type="primary"
                  >
                    Enquire Now{" "}
                    <i
                      style={{
                        color: "white",
                        marginLeft: "0.5em",
                        fontSize: "18px",
                      }}
                      class="fa fa-arrow-circle-right"
                      aria-hidden="true"
                    ></i>
                  </Button>
                </Link>
                {/* <CurrencyFormat
                    value={stock.mileage}
                    displayType={"text"}
                    thousandSeparator={true}
                    sufffix={"km"}
                    //format='R### ### ### ###'
                    renderText={(value) => (
                      <div style={{ display: "inline-block" }}>{value}</div>
                    )}
                  /> */}
              </li>
            </ul>
          </div>
        </div>
      ) : null
      //</Fade>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(StockItemCard);

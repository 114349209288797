import React, { useState, Component } from "react";
import styled from "styled-components";
import { Row, Col, Form, Input, Button, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import { data } from "jquery";
//import { Modal as SuccessModal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";

const { Option } = Select;
const { TextArea } = Input;
const selectBefore = (
  <Form.Item
    name='title'
    rules={[{ required: false, message: false }]}
    style={{ marginBottom: "0px" }}
  >
    <Select placeholder='Title' className='select-before'>
      <Option value='Mr'>Mr</Option>
      <Option value='Miss'>Miss</Option>
    </Select>
  </Form.Item>
);
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const StyledInputTestDrive = styled(Input)`

//padding-left: 1.2em !important;
height: 45px !important;

  box-shadow:none !important;
background-color: transparent!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid #06141f !important;
border-radius: 0px;
color: #06141f!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input-group-addon .ant-select {
  margin: 0em !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
padding-right: 20px !important;
color: #06141f !important;
}
.ant-select-arrow {

color: #06141f !important;

}
.ant-input-group-addon {
padding: 0px !important;
color: #06141f !important;
background-color: #05141F !important;
border: 0px solid grey !important;

}
input.ant-input {
padding: 0;
color: #06141f !important;
border: none;
background: transparent !important;
outline: none;
}
.ant-input-affix-wrapper {

height: 43px !important;
border: none !important;}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: #05141F !important;

  box-shadow:none !important;
}
:active{
  border-color: #05141F !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: #05141F !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelectTestDrive = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid rgb(6, 20, 31)!important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid rgb(6, 20, 31) !important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: rgb(6, 20, 31)!important;

    box-shadow: none !important;
  }
  :active {
    border-color: rgb(6, 20, 31) !important;

    box-shadow: none !important;
  }
  :focus {
    border-color: rgb(6, 20, 31)  !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: rgb(6, 20, 31) !important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
const StyledInput = styled(Input)`

  //padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid black !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  .ant-input-group-addon .ant-select {
    margin: 0em !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 20px !important;
  color: white !important;
}
.ant-select-arrow {

  color: white !important;

}
.ant-input-group-addon {
  max-height: 3em !important;
  padding: 0px !important;
  color: white !important;
  background-color: #05141F !important;
  border: 0px solid grey !important;
 
}
.ant-input-affix-wrapper {

  height: 43px !important;
  border: none !important;}
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: #05141F !important;

    box-shadow:none !important;
}
  :active{
    border-color: #05141F !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: #05141F !important;
    
     box-shadow:none !important;
   
  }
  }
`;

const StyledTextArea = styled(TextArea)`
padding-left:0.4em !important;
  box-shadow:none !important;
background-color: white!important;
background-image: none;
border: none;
border: 0px solid black !important;
border: 1px solid black !important;
border-radius: 0px;
color: black!important;
//box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
.ant-input:focus, .ant-input-focused {
  border-color:  transparent !important;
  -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}
.ant-input {
border-color: transparent !important;
-webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
box-shadow: none !important;
border-right-width: 1px;
outline: 0;
}
:placeholder {
  color: lightgrey !important;
}
:hover {
  border-color: red !important;

  box-shadow:none !important;
}
:active{
  border-color: red !important;
 
   box-shadow:none !important;
 
}
:focus{
  border-color: red !important;
  
   box-shadow:none !important;
 
}
}
`;
const StyledSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #ffffff;
    /* border: 1px solid #e9e9e9; */
    height: 45px !important;
    padding: 5px 11px !important;
    border: 1px solid black !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-multiple .ant-select-selector {
    height: 45px !important;
  }
  border: 1px solid black!important;
  border-radius: 0em !important;
  height: 45px !important;
  box-shadow: none !important;
  :hover {
    border-color: black !important;

    box-shadow: none !important;
  }
  :active {
    border-color: black!important;

    box-shadow: none !important;
  }
  :focus {
    border-color: black !important;

    box-shadow: none !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: black!important;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none !important;
  }
`;
class K2500 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,

      pantherametalVisible: false,
      flareredVisible: false,
      clearwhiteVisible: true,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      ceramicsilverVisible: false,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
  }
  clearwhiteClick = () => {
    this.setState({
      clearwhiteVisible: true,
      pantherametalVisible: false,
      flareredVisible: false,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      milkyBeigeVisible: false,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    });
  }
  pantherametalClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: true,
      flareredVisible: false,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      ceramicsilverVisible: false,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    });
  }
  flareredClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: false,
      flareredVisible: true,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      ceramicsilverVisible: false,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    });
  }
  deepChromaBlueClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: false,
      flareredVisible: false,
      DeepChromaBlueVisible: true,
      astraBlueVisible: false,
      ceramicsilverVisible: false,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    });
  }
  astraBlueClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: false,
      flareredVisible: false,
      DeepChromaBlueVisible: false,
      astraBlueVisible: true,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    });
  }
  ceramicsilverClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: false,
      flareredVisible: false,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      ceramicsilverVisible: true,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    });
  }
  auroraBlackPearlClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: false,
      flareredVisible: false,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      ceramicsilverVisible: false,
      auroraBlackPearlVisible: true,
      runwayRedVisible: false,
      silkySilverVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    });
  }
  runwayRedClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: false,
      flareredVisible: false,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      ceramicsilverVisible: false,
      auroraBlackPearlVisible: false,
      runwayRedVisible: true,
      silkySilverVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: false,
    });
  }
  silkySilverClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: false,
      flareredVisible: false,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      ceramicsilverVisible: false,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      silkySilverVisible: true,
      steelGreyVisible: false,
    });
  }
  steelGreyClick = () => {
    this.setState({
      clearwhiteVisible: false,
      pantherametalVisible: false,
      flareredVisible: false,
      DeepChromaBlueVisible: false,
      astraBlueVisible: false,
      ceramicsilverVisible: false,
      auroraBlackPearlVisible: false,
      runwayRedVisible: false,
      silkySilverVisible: false,
      silkySilverVisible: false,
      steelGreyVisible: true,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  showTestDrive = () => {
    this.setState({
      visibleTestDrive: true,
    });
  };
  handleOkTestDrive = e => {
    console.log(e);
    this.setState({
      visibleTestDrive: false,
    });
  };

  handleCancelTestDrive = e => {
    console.log(e);
    this.setState({
      visibleTestDrive: false,
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
  };
  onFinish = (values) => {
    const MSG = {
      to: [
        "ianels182@gmail.com",
        //"ianels182@gmail.com",
        //"allan@megatheron.co.za",
        //"xander@megatheron.co.za",
      ],
      from: "info@mtgroup.co.za",
      subject: "New Test Drive Request",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Test Drive Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Test Drive Enquiry from <br /> Kia Mahikeng website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.firstName} ${values.surname}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; K2500 <br />
                 
                      
                       
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2022@Mega Theron Group</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        visibleTestDrive: false,
        submitDisabled: true,
        title: "",
        firstName: "",
        surname: "",
        number: "",
        email: "",
        make: "",
      });
    });
  };
  //onFinish = (values) => {
  //  let api_url_stage =
  //    "https://aws-stage-leadsv3.cmscloud.co.za/api/lead/savelead";
  //  let api_token_stage = "9D20B459-5A85-45C4-92B4-7D0F7E7FA50B";
  //  let api_url_prod = "https://leadsv3.cmscloud.co.za/api/lead/savelead";
  //  let api_token_prod = "B9CDC074-49D8-401F-81BB-4DE4F6B83CA1";
  //  // POST request using fetch with set headers
  //  const requestOptions = {
  //    method: "POST",
  //    headers: {
  //      "Content-Type": "application/json",
  //      Authorization: api_token_stage,
  //    },
  //    body: JSON.stringify({
  //      lead: {
  //        dealerRef: "MT001",
  //        dealerFloor: "USED",
  //        source: "MT-DEALERWEBSITE",
  //        //transactionID: data.id,
  //      },
  //      contact: {
  //        email: values.email,
  //        cellPhone: values.cellPhone,
  //        surname: values.surname,
  //        firstName: values.firstName,
  //        title: values.title,
  //      },
  //    }),
  //  };
  //  fetch(api_url_stage, requestOptions)
  //    .then((response) => response.json())
  //    .then((res) => {
  //      // Unfortunately, fetch doesn't send (404 error) into the cache itself
  //      // You have to send it, as I have done below
  //      if (res.status >= 400) {
  //        throw new Error("Server responds with error!");
  //      }
  //      return res.json();
  //    })
  //    .then(() =>
  //      this.setState({
  //        code: "00",
  //        leadReference: "fe7521e8-d48e-420d-a8d5-c9821a267a7c",
  //        status: "Success",
  //        message: "",
  //      })
  //    );
  //  (err) => {
  //    this.setState({
  //      err,
  //      code: "01",
  //      leadReference: "",
  //      status: "Failed",
  //      message: "Rejected - Lead DealerRef Invalid: 123M",
  //    });
  //  };
  //};
  render() {
    return (
      <div className='wrap-body-inner' style={{ margin: "auto", marginTop: "10em", maxWidth: "100em", backgroundColor: "transparent" }}>

        {/*<div className="hidden-xs">
          <div className="row">
            <div className="col-lg-6">
              <ul className="ht-breadcrumb pull-left">
                <li className="home-act">
                  <Link to="/">
                    <i className="fa fa-home"></i>
                  </Link>
                </li>
                <li className="active">Contact</li>
              </ul>
            </div>
          </div>
        </div>*/}
        <div className='banner-item banner-2x banner-bg-11 color-inher m-b-lg-0' style={{ height: "40em", backgroundColor: "transparent" }}>
          <img
            //className="banner-img-home"
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "34em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 59 : 110,
              right: 0,
              zIndex: -1,

            }}
            src={window.isPhone ? `${process.env.PUBLIC_URL}/images/k2500-banner-mobile.jpg` : `${process.env.PUBLIC_URL}/images/k2500-banner.jpg`}
            alt='logo'
          />
          <p style={{ textAlign: "left", padding: 0, color: "white", marginTop: window.isPhone ? "-2em" : "0em", textTransform: "none", fontWeight: 300, fontSize: window.isPhone ? "40px" : "65px" }}>
            <strong>Kia K2500 </strong>
          </p>
          <h6 style={{ textAlign: "left", padding: 0, color: "white", fontSize: window.isPhone ? "20px" : "35px", textTransform: "none", fontWeight: 200, }}>
            Multitasking made Easy
          </h6>
          <div style={{ textAlign: "center", marginTop: window.isPhone ? "5em" : "19em" }}>
            <a
              href="/kiamahikeng/#test-drive"
              className='ht-btn ht-btn-default  p-l-lg-30 p-r-lg-30'
              style={{ textTransform: "none", backgroundColor: "#36434c", border: "none", marginTop: window.isPhone ? "23em" : "30px", }}
            >
              BOOK A TEST DRIVE
            </a>
            {window.isPhone ? <br /> : " "}
            <a
              href='https://www.kia.co.za/assets/images/vehicles/vehicle_33/kia_brochure_k2500.pdf'
              target="_blank"
              className='ht-btn ht-btn-default p-l-lg-30 p-r-lg-30'
              style={{
                textTransform: "none",
                marginLeft: window.isPhone ? "" : "1em",
                textTransform: "none", backgroundColor: "#36434c", border: "none", marginTop: window.isPhone ? "1em" : "30px",

              }}
            >
              BROCHURE
            </a>
          </div>
        </div>
        <div className=' banner-2x banner-bg-11 color-inher m-b-lg-0' style={{ height: "40em", backgroundColor: "transparent" }}>
          <img
            //className="banner-img-home"
            style={{
              width: "100vw",
              position: "absolute",
              height: window.isPhone ? "35em" : "40em",
              objectFit: "cover",
              top: window.isPhone ? 690 : 670,
              right: 0,
              zIndex: -1,

            }}

            src={window.isPhone ? `${process.env.PUBLIC_URL}/images/k2500-interior-mobile.jpg` : `${process.env.PUBLIC_URL}/images/k2500-interior.jpg`}
            alt='logo'
          />
          <p style={{ textAlign: "left", marginBottom: "0em", padding: 0, color: "white", marginTop: window.isPhone ? "0em" : "2em", textTransform: "none", fontWeight: 300, fontSize: window.isPhone ? "25px" : "35px" }}>
            <span style={{ lineHeight: "1em" }}>Multifunctional Centre console </span>
          </p>
          <h6 style={{ textAlign: "left", padding: 0, color: "white", fontSize: window.isPhone ? "12px" : "17px", textTransform: "none", fontWeight: 200, lineHeight: "1.5em" }}>
            For the organisation of small miscellaneous items. The front centre<br /> seatback folds down to reveal a multifunctional console for practical <br />storage, in addition to a storage space within the centre console.
          </h6>
        </div>
        <div className=' banner-2x banner-bg-11 color-inher m-b-lg-0' style={{ height: window.isPhone ? "75em" : "30em", backgroundColor: "transparent" }}>
          <Row style={{
            width: "100vw",
            position: "absolute",
            height: window.isPhone ? "18em" : "40em",
            objectFit: "cover",
            top: window.isPhone ? 1120 : 1230,
            right: 0,
            zIndex: -1,

          }} >
            <Col xs={24} sm={8}>
              <img
                //className="banner-img-home"
                style={{
                  height: "29em",
                  width: "100%",
                  objectFit: "cover",
                  right: 0,
                }}
                src={`${process.env.PUBLIC_URL}/images/k2500-1.jpg`}
                alt='logo'
              />
            </Col>
            <Col xs={24} sm={8}>
              <img
                //className="banner-img-home"
                style={{
                  width: "100%",
                  height: "29em",
                  objectFit: "cover",
                  right: 0,
                }}
                src={`${process.env.PUBLIC_URL}/images/k2500-2.jpg`}
                alt='logo'
              />
            </Col>
            <Col xs={24} sm={8}>
              <img
                //className="banner-img-home"
                style={{
                  width: "100%",
                  height: "29em",
                  objectFit: "cover",
                  right: 0,
                }}
                src={`${process.env.PUBLIC_URL}/images/k2500-3.jpg`}
                alt='logo'
              />
            </Col>
          </Row>

        </div>
        <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "50px" }}>
          <strong>Colour</strong>
        </p>
        {this.state.clearwhiteVisible ?
          <div>
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <img
                  //className="banner-img-home"
                  style={{
                    width: window.isPhone ? "100%" : "70%",
                  }}
                  src={`${process.env.PUBLIC_URL}/images/k2500-ClearWhite.jpg`}
                  alt='logo'
                />
              </Col>
            </Row>
          </div> :
          this.state.pantherametalVisible ?
            <div>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <img
                    //className="banner-img-home"
                    style={{
                      width: window.isPhone ? "100%" : "70%",

                    }}
                    src={`${process.env.PUBLIC_URL}/images/k2500-pantherametal.jpg`}
                    alt='logo'
                  />
                </Col>
              </Row>
            </div>
            : this.state.flareredVisible ?
              <div>
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <img
                      //className="banner-img-home"
                      style={{
                        width: window.isPhone ? "100%" : "70%",

                      }}
                      src={`${process.env.PUBLIC_URL}/images/k2500-flarered.jpg`}
                      alt='logo'
                    />
                  </Col>
                </Row>
              </div>
              : this.state.DeepChromaBlueVisible ?
                <div>
                  <Row>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <img
                        //className="banner-img-home"
                        style={{
                          width: window.isPhone ? "100%" : "70%",

                        }}
                        src={`${process.env.PUBLIC_URL}/images/k2500-auroradeepchromablue.jpg`}
                        alt='logo'
                      />
                    </Col>
                  </Row>
                </div>
                : this.state.ceramicsilverVisible ?
                  <div>
                    <Row>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <img
                          //className="banner-img-home"
                          style={{
                            width: window.isPhone ? "100%" : "70%",

                          }}
                          src={`${process.env.PUBLIC_URL}/images/k2500-ceramicsilver.jpg`}
                          alt='logo'
                        />
                      </Col>
                    </Row>
                  </div>

                  : this.state.auroraBlackPearlVisible ?
                    <div>
                      <Row>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <img
                            //className="banner-img-home"
                            style={{
                              width: window.isPhone ? "100%" : "70%",

                            }}
                            src={`${process.env.PUBLIC_URL}/images/k2500-intensered.jpg`}
                            alt='logo'
                          />
                        </Col>
                      </Row>
                    </div>
                    : this.state.astraBlueVisible ?
                      <div>
                        <Row>
                          <Col span={24} style={{ textAlign: "center" }}>
                            <img
                              //className="banner-img-home"
                              style={{
                                width: window.isPhone ? "100%" : "70%",

                              }}
                              src={`${process.env.PUBLIC_URL}/images/k2500-astraBlue.jpg`}
                              alt='logo'
                            />
                          </Col>
                        </Row>
                      </div>
                      : this.state.runwayRedVisible ?
                        <div>
                          <Row>
                            <Col span={24} style={{ textAlign: "center" }}>
                              <img
                                //className="banner-img-home"
                                style={{
                                  width: window.isPhone ? "100%" : "70%",

                                }}
                                src={`${process.env.PUBLIC_URL}/images/k2500-intenseRedAuroraDeepChromaBlue.jpg`}
                                alt='logo'
                              />
                            </Col>
                          </Row>
                        </div>
                        : this.state.silkySilverVisible ?
                          <div>
                            <Row>
                              <Col span={24} style={{ textAlign: "center" }}>
                                <img
                                  //className="banner-img-home"
                                  style={{
                                    width: window.isPhone ? "100%" : "70%",

                                  }}
                                  src={`${process.env.PUBLIC_URL}/images/k2500-glacierwhitepearlauroradeepchromablue.jpg`}
                                  alt='logo'
                                />
                              </Col>
                            </Row>
                          </div>
                          : this.state.steelGreyVisible ?
                            <div>
                              <Row>
                                <Col span={24} style={{ textAlign: "center" }}>
                                  <img
                                    //className="banner-img-home"
                                    style={{
                                      width: window.isPhone ? "100%" : "70%",

                                    }}
                                    src={`${process.env.PUBLIC_URL}/images/k2500-steelgrey.jpg`}
                                    alt='logo'
                                  />
                                </Col>
                              </Row>
                            </div>
                            : null}
        <Row style={{ maxWidth: "48em", margin: "auto" }}>
          <div
            onClick={this.clearwhiteClick}
            style={{
              margin: "auto",
              marginTop: "1em",
              backgroundColor: "white",
              boxShadow: this.state.clearwhiteVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              border: "1px solid lightgrey",
              height: "4.5em",
              borderRadius: "6em"


            }}
          >
          </div>
          {/* <div
            onClick={this.pantherametalClick}
            style={{
              margin: "auto",
              marginTop: "1em",
              border: "1px solid lightgrey",
              backgroundColor: "#2c2e33",
              boxShadow: this.state.pantherametalVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div>
          <div
            onClick={this.flareredClick}
            style={{
              margin: "auto",
              marginTop: "1em",
              border: "1px solid lightgrey",
              backgroundColor: "#aa1f24",
              boxShadow: this.state.flareredVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div>
          <div
            onClick={this.deepChromaBlueClick}
            style={{
              margin: "auto",
              marginTop: "1em",
              backgroundColor: "#1f294d",
              border: "1px solid lightgrey",
              boxShadow: this.state.DeepChromaBlueVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div>
          <div

            onClick={this.ceramicsilverClick}
            style={{
              margin: "auto",
              border: "1px solid lightgrey",
              marginTop: "1em",
              backgroundColor: "#4c494a",
              boxShadow: this.state.ceramicsilverVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div>
          <div
            onClick={this.auroraBlackPearlClick}
            style={{
              margin: "auto",
              border: "1px solid lightgrey",
              marginTop: "1em",
              backgroundColor: "#1f1f21",
              boxShadow: this.state.auroraBlackPearlVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div>
          <div

            onClick={this.astraBlueClick}
            style={{
              margin: "auto",
              border: "1px solid lightgrey",
              marginTop: "1em",
              backgroundColor: "#24363f",
              boxShadow: this.state.astraBlueVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div> */}
          {/* <div
            onClick={this.runwayRedClick}
            style={{
              margin: "auto",
              border: "1px solid lightgrey",
              marginTop: "1em",
              backgroundColor: "#580c14",
              boxShadow: this.state.runwayRedVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div>
          <div
            onClick={this.silkySilverClick}
            style={{
              margin: "auto",
              border: "1px solid lightgrey",
              marginTop: "1em",
              backgroundColor: "#77787c",
              boxShadow: this.state.silkySilverVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div>
          <div
            onClick={this.steelGreyClick}
            style={{
              margin: "auto",
              border: "1px solid lightgrey",
              marginTop: "1em",
              backgroundColor: "#59595d",
              boxShadow: this.state.steelGreyVisible ? "0 0 10px 8px rgb(128 128 128 / 75%)" : "",
              width: "4.5em",
              height: "4.5em",
              borderRadius: "6em",
              cursor: "pointer"


            }}
          >
          </div> */}
        </Row>
        <Row style={{ margin: "auto", marginTop: "1em", marginBottom: "2em", textAlign: "center" }}>
          <Col span={24} style={{ margin: "auto", }}>
            {this.state.clearwhiteVisible ?
              <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                <strong>Clear White</strong>
              </p> :
              this.state.pantherametalVisible ?
                <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                  <strong>Panthera Metal</strong>
                </p>
                : this.state.flareredVisible ?
                  <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                    <strong>Flare Red</strong>
                  </p>
                  : this.state.DeepChromaBlueVisible ?
                    <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                      <strong>Deep Chroma Blue</strong>
                    </p>
                    : this.state.astraBlueVisible ?
                      <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                        <strong>Astra Blue</strong>
                      </p>
                      : this.state.ceramicsilverVisible ?
                        <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                          <strong>Ceramic Silver</strong>
                        </p>
                        : this.state.auroraBlackPearlVisible ?
                          <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                            <strong>Aurora Black Pearl</strong>
                          </p>
                          : this.state.runwayRedVisible ?
                            <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                              <strong>Intense Red + Aurora Black Pearl (special order)</strong>
                            </p>
                            : this.state.silkySilverVisible ?
                              <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                                <strong>Glacier White Pearl + Aurora Black Pearl (special order)</strong>
                              </p>
                              : this.state.steelGreyVisible ?
                                <p style={{ marginBottom: 0, textAlign: "center", marginTop: "0em", padding: 0, color: "black", marginTop: window.isPhone ? "1em" : "0em", textTransform: "none", fontWeight: 300, fontSize: "30px" }}>
                                  <strong>Punchy Orange + Clear White (special order)</strong>
                                </p>
                                : null}</Col></Row>

        <Row id="test-drive" style={{ maxWidth: "60em", margin: "auto" }}>

          <h2
            className='f-weight-600'
            style={{
              fontSize: window.isPhone ? "25px" : "40px",
              textAlign: "left",
              whiteSpace: "nowrap",
              color: "#05141F",
              marginBottom: "1em",

            }}
          >
            <br />
            - BOOK A TEST DRIVE
          </h2>
          <Form
            onFinish={this.handleSubmit}
            initialValues={{
              remember: true,
            }}
            name='wrap'
            labelCol={{ flex: "110px" }}
            labelAlign='left'
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
          >
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <Form.Item
                name='firstName'
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive

                  placeholder='Name'
                />
              </Form.Item>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <Form.Item
                name='surname'
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder='Surname' />
              </Form.Item>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <Form.Item
                name='number'
                rules={[{ required: true, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder='Number' />
              </Form.Item>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <Form.Item
                name='email'
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledInputTestDrive placeholder='Email' />
              </Form.Item>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <Form.Item
                name='make'
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledSelectTestDrive
                  defaultValue="K2500"
                  bordered={false}
                  style={{
                    borderRadius: "0em",
                    height: 50,
                    color: "#06141f"
                  }}
                  placeholder='Vehicle Model'
                //onChange={this.setCarEngineCapacity}
                >
                  <Option
                    value="K2500"
                  >
                    K2500
                  </Option>

                </StyledSelectTestDrive >
              </Form.Item>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <Form.Item
                name='dealership'
                rules={[{ required: false, message: false }]}
                hasFeedback
              >
                <StyledSelectTestDrive
                  bordered={false}
                  style={{

                    borderRadius: "0em",
                    height: 50,
                    color: "#06141f"
                  }}
                  placeholder='Dealership'
                //onChange={this.setCarEngineCapacity}
                >
                  <Option key={1} value='mahikeng'>
                    Kia Mahikeng
                  </Option>

                </StyledSelectTestDrive >
              </Form.Item>
            </div>

            <div className='col-lg-12 m-t-lg-12 text-center'>
              <Form.Item>
                <Button
                  style={{
                    fontFamily: "",
                    border: "1px solid #06141f",
                    fontSize: "15px",
                    fontWeight: 600,
                    borderRadius: "0em",
                    backgroundColor: "#06141f",
                    color: "white",
                    height: "55px",
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    textShadow: "none",
                    margin: "auto",
                    width: "100%"
                  }}
                  type='primary'
                  htmlType='submit'
                >SEND ENQUIRY
                </Button>{" "}
              </Form.Item>{" "}
              <p
                style={{
                  color: "#06141f",
                  //marginBottom: "2.5em",
                  marginTop: "0em",
                  fontSize: "13px",
                  lineHeight: "1.5em",
                }}
              >
                By submitting this form, you agree to our{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                  }}
                >
                  <Link style={{ color: "#06141f" }} to='/terms' >
                    Terms of Use
                  </Link>
                </span>{" "}
                and{" "}
                <span
                  style={{
                    fontSize: "13px",
                    textDecoration: "underline",
                    color: "#06141f"
                  }}
                >
                  <Link style={{ color: "#06141f" }} to='/privacy-policy' >
                    Privacy Statement
                  </Link>
                </span>
                .
              </p>{" "}
            </div>
          </Form>
        </Row>

        <Modal
          visible={this.state.visible}
          style={{ textAlign: "center" }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo.png`}
            alt='Logo'
            style={{ width: "20em", margin: "auto", marginTop: "2em", }}
          />
          <h3
            style={{
              marginTop: "2em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for contacting
            <br />
            <span style={{ color: "rgb(6, 20, 31)" }}>
              Kia Mahikeng.
            </span>{" "}
            <br />
            <br />
            <span style={{ color: "black ", fontSize: "17px" }}>
              Our vehicle specialist will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}

          <br />
        </Modal>
      </div >
    );
  }
}
export default K2500;

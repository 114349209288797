import axios from "axios";
import $ from "jquery";
import {
  FETCH_FILTRED_STOCK,
  FETCH_PRODUCTS,
  BASKET_ADD,
  BASKET_REMOVE_ITEM,
  FETCH_STOCK,
  FETCH_SINGLE_STOCK,
  BASKET_EDIT_ITEM,
  WISHLIST_ADD_PRODUCT,
  WISHLIST_REMOVE_PRODUCT,
  SELECTED_PRODUCT,
  SELECTED_STOCK,
  FILTER_ACTIVATION,
} from "./types";

export const fetchProducts = (category) => async (dispatch) => {
  const res = await axios.get(`/api/products/:${category}`);
  dispatch({ type: FETCH_PRODUCTS, payload: res.data });
};

export function fetchSingleProduct(product) {
  return {
    type: SELECTED_PRODUCT,
    payload: product,
  };
}

export function fetchSingleStock(product) {
  console.log(product);
  return {
    type: SELECTED_STOCK,
    payload: product,
  };
}

export function addToBasket(product, quantity) {
  return {
    type: BASKET_ADD,
    payload: {
      product,
      quantity,
    },
  };
}

export function removeFromBasket(id) {
  return {
    type: BASKET_REMOVE_ITEM,
    payload: id,
  };
}

export function changeBasketItem(product, quantity) {
  return {
    type: BASKET_EDIT_ITEM,
    payload: {
      product,
      quantity,
    },
  };
}

export function addToWishList(product) {
  return {
    type: WISHLIST_ADD_PRODUCT,
    payload: product,
  };
}

export function removeFromWishList(product) {
  return {
    type: WISHLIST_REMOVE_PRODUCT,
    payload: product,
  };
}
//export function fetchStock() {
//  return (dispatch) =>
//    new Promise((resolve, reject) => {
//      fetch(
//        "https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient",
//        {
//          method: "GET",
//          mode: "cors",
//        }
//      ).then(async (res) => {
//        if (!res.ok) {
//          return reject({ message: await res.text() });
//        }
//        return resolve(
//          dispatch({
//            type: "FETCH_STOCK",
//            data: await res.json(),
//          })
//        );
//      });
//    }).catch((err) => {
//      throw err.message;
//    });
//}
//export const fetchStock = () => async (dispatch) => {
//  const res = await axios.get("/api/stock");
//  dispatch({ type: FETCH_STOCK, payload: res.data });
//};
export function fetchStock() {
  return new Promise(async (resolve, reject) => {
    $.ajax({
      type: "GET",
      url: "https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient",
      contentType: "application/json",
      crossDomain: true,
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.token);
      },
      success: async (data) => {
        return resolve(data.allStock.filter(allStock => allStock.megaDealers === 'KIA'));
      },
      error: async (xhr, ajaxOptions, thrownError) => {
        if (xhr.responseText) {
          return reject({ message: xhr.responseText });
        } else if (thrownError) {
          return reject({ message: thrownError });
        } else {
          return reject({ message: "No Connection Found!" });
        }
      },
    });
  });
}
export const fetchStockId = (id) => async (dispatch) => {
  const res = await axios.get(`/api/stock/:${id}`);
  dispatch({ type: FETCH_SINGLE_STOCK, payload: res.data });
};

export const fetchFiltredStock = (filters) => async (dispatch) => {
  const fetchedStock = await fetchStock();
  console.log("**********************************");
  console.log(filters);
  console.log("**********************************");
  var {
    series,
    condition,
    bodyType,
    make,
    modelYear,
    modelDesc,
    mileage,
    transmission,
    stockDrive,
    features,
    branch,
    extColour,
    fuelType,
    onSale,
    priceRange = "",
    mileageRange = "",
    yearRange = "",
  } = filters;
  var result = {};

  result = fetchedStock;
  // if (condition != undefined && condition[0] !== "" && condition.length > 0) {
  //   result = result.filter(function (stock) {
  //     for (var i = 0; i < condition.length; i++) {
  //       if (stock.condition === condition[i]) {
  //         return true;
  //       }
  //     }
  //   });
  // }
  if (condition != undefined && condition !== "")
    result = result.filter(function (stock) {
      return stock.condition === condition;
    });
  if (series != undefined && series[0] !== "" && series.length > 0) {
    result = result.filter(function (stock) {
      for (var i = 0; i < series.length; i++) {
        if (stock.series === series[i]) {
          return true;
        } else if (stock.series === series) {
          return stock.series === series;
        }
      }
    });
  }
  if (bodyType != undefined && bodyType[0] !== "" && bodyType.length > 0) {
    result = result.filter(function (stock) {
      for (var i = 0; i < bodyType.length; i++) {
        if (stock.bodyType === bodyType[i]) {
          return true;
        }
      }
    });
  }
  if (make != undefined && make[0] !== "" && make.length > 0) {
    result = result.filter(function (stock) {
      for (var i = 0; i < make.length; i++) {
        if (stock.make === make[i]) {
          return true;
        } else if (stock.make === make) {
          return stock.make === make;
        }
      }
    });
  }
  if (modelYear != undefined && modelYear[0] !== "" && modelYear.length > 0) {
    result = result.filter(function (stock) {
      for (var i = 0; i < modelYear.length; i++) {
        if (stock.modelYear === modelYear[i]) {
          return true;
        } else if (stock.modelYear === modelYear) {
          return stock.modelYear === modelYear;
        }
      }
    });
  }
  if (
    transmission != undefined &&
    transmission[0] !== "" &&
    transmission.length > 0
  ) {
    result = result.filter(function (stock) {
      for (var i = 0; i < transmission.length; i++) {
        if (stock.transmission === transmission[i]) {
          return true;
        }
      }
    });
  }
  if (
    stockDrive != undefined &&
    stockDrive[0] !== "" &&
    stockDrive.length > 0
  ) {
    result = result.filter(function (stock) {
      for (var i = 0; i < stockDrive.length; i++) {
        if (stock.stockDrive === stockDrive[i]) {
          return true;
        }
      }
    });
  }
  //if (features != undefined && features !== "")
  //  result = result.filter(function (stock) {
  //    return stock.features === features;
  //  });
  if (branch != undefined && branch[0] !== "" && branch.length > 0) {
    result = result.filter(function (stock) {
      for (var i = 0; i < branch.length; i++) {
        if (stock.branch === branch[i]) {
          return true;
        }
      }
    });
  }
  if (extColour != undefined && extColour[0] !== "" && extColour.length > 0) {
    result = result.filter(function (stock) {
      for (var i = 0; i < extColour.length; i++) {
        if (stock.extColour === extColour[i]) {
          return true;
        }
      }
    });
  }
  if (fuelType != undefined && fuelType[0] !== "" && fuelType.length > 0) {
    result = result.filter(function (stock) {
      for (var i = 0; i < fuelType.length; i++) {
        if (stock.fuelType === fuelType[i]) {
          return true;
        }
      }
    });
  }
  if (onSale != undefined && onSale !== false)
    result = result.filter(function (stock) {
      return stock.onSale === true;
    });
  if (modelDesc != undefined && modelDesc[0] !== "" && modelDesc.length > 0) {
    result = result.filter(function (stock) {
      for (var i = 0; i < modelDesc.length; i++) {
        if (stock.modelDesc === modelDesc[i]) {
          return true;
        } else if (stock.modelDesc === modelDesc) {
          return stock.modelDesc === modelDesc;
        }
      }
    });
  }
  //if (mileage != undefined && mileage !== "")
  //  result = result.filter(function (stock) {
  //    return stock.mileage === mileage;
  //  });
  //Slider
  var min = 1000 * priceRange.slice(1, priceRange.indexOf(","));
  var max =
    1000 *
    priceRange.slice(
      priceRange.indexOf("-") + 3,
      priceRange.indexOf(",", priceRange.indexOf("-"))
    );

  console.log(min);
  console.log(max);
  if (priceRange != undefined && priceRange !== "")
    result = result.filter(function (stock) {
      console.log(
        parseInt(stock.price, 10) <= max && parseInt(stock.price, 10) >= min
      );
      return (
        parseInt(stock.price, 10) <= max && parseInt(stock.price, 10) >= min
      );
    });
  //Slider-Mileage
  var min = 1000 * mileageRange.slice(1, mileageRange.indexOf(","));
  var max =
    1000 *
    mileageRange.slice(
      mileageRange.indexOf("-") + 3,
      mileageRange.indexOf(",", mileageRange.indexOf("-"))
    );

  console.log(min);
  console.log(max);
  if (mileageRange != undefined && mileageRange !== "")
    result = result.filter(function (stock) {
      console.log(
        parseInt(stock.mileage, 10) <= max && parseInt(stock.mileage, 10) >= min
      );
      return (
        parseInt(stock.mileage, 10) <= max && parseInt(stock.mileage, 10) >= min
      );
    });
  //Slider-Year
  var minYear = 1 * yearRange.slice(1, yearRange.indexOf(" -  ") - 0);
  var maxYear = 1 * yearRange.slice(yearRange.indexOf("-") + 2);

  console.log(minYear);
  console.log(maxYear);
  if (yearRange != undefined && yearRange !== "")
    result = result.filter(function (stock) {
      console.log(
        parseInt(stock.modelYear, 10) <= maxYear &&
        parseInt(stock.modelYear, 10) >= minYear
      );
      return (
        parseInt(stock.modelYear, 10) <= maxYear &&
        parseInt(stock.modelYear, 10) >= minYear
      );
    });
  dispatch({ type: FETCH_FILTRED_STOCK, payload: result });
};

export function turnOnHomefilter() {
  return {
    type: FILTER_ACTIVATION,
    payload: true,
  };
}

export function turnOffHomefilter() {
  return {
    type: FILTER_ACTIVATION,
    payload: false,
  };
}
export function setCarConditionUsed() {
  return {
    type: FILTER_ACTIVATION,
    payload: true,
  };
}

import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import Home from "./Home";
import SharedLayout from "./SharedLayout";
import Stockshop from "./StockSpace/StockShop";
import StockNew from "./StockSpace/StockNew";
import StockPromotions from "./StockSpace/StockPromotions";
import ProductsShop from "./ProductsSpace/ProductsShop";
import About from "./about-kia";
import BookService from "./bookService";
import ContactUs from "./contactUs";
import Picanto from "./Picanto";
import Pegas from "./Pegas";
import Sorento from "./Sorento";
import Rio from "./Rio";
import Carnival from "./Carnival";
import K2700 from "./K2700";
import K2500 from "./K2500";
import Sonet from "./Sonet";
import Sportage from "./Sportage";
import Seltos from "./Seltos";
import CartFull from "./CartFull";
import StockDetails from "./StockSpace/StockDetails";
import KiaRange from "./KiaRange";
import DownloadCenter from "./downloadCenter";
import WishList from "./WishList";
import Blogs from "./Blogs/blogs";
import AllPosts from "./Blogs/all-posts";
import OnePost from "./Blogs/one-post";
import LegalNotice from "./Legal/legal-notice";
import PopiaManual from "./Legal/POPIA-manual";
import PrivacyPolicy from "./Legal/privacy-policy";
import Terms from "./Legal/terms";
import Specials from "./Specials";

class App extends Component {
  constructor() {
    super();
    this.state = {
      stockfilters: {},
    };
  }

  setStockFilters(stockfilters) {
    this.setState({ stockfilters });
  }

  render() {
    return (
      <BrowserRouter>
        <SharedLayout stockfilters={this.setStockFilters.bind(this)}>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/products" component={ProductsShop} />
          <Route
            path="/stock"
            render={() => <Stockshop filters={this.state.stockfilters} />}
          />
          <Route
            path="/Specials"
            render={() => <StockPromotions filters={this.state.stockfilters} />}
          />
          <Route
            path="/new-vehicles"
            render={() => <StockNew filters={this.state.stockfilters} />}
          />
          <Route path="/stocko" component={StockDetails} />
          <Route path="/kia-range" component={KiaRange} />
          <Route path="/picanto" component={Picanto} />
          <Route path="/rio" component={Rio} />
          <Route path="/pegas" component={Pegas} />
          <Route path="/sonet" component={Sonet} />
          <Route path="/seltos" component={Seltos} />
          <Route path="/k2700" component={K2700} />
          <Route path="/k2500" component={K2500} />
          <Route path="/sorento" component={Sorento} />
          <Route path="/sportage" component={Sportage} />
          <Route path="/carnival" component={Carnival} />
          <Route path="/about-kia" component={About} />
          <Route path="/bookService" component={BookService} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/downloadCenter" component={DownloadCenter} />
          <Route path="/products_cart" component={CartFull} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/wishlist" component={WishList} />
          <Route path="/stock/vehicle-details" component={Stockshop} />
          <Route path="/products/vehicle-details" component={ProductsShop} />
          <Route path="/legal-notice" component={LegalNotice} />
          <Route path="/POPIA-manual" component={PopiaManual} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms" component={Terms} />
          <Route path="/Specials" component={Specials} />

          <Route component={OnePost} path="/:slug"></Route>
          <Route component={AllPosts} path="/all-posts"></Route>
        </SharedLayout>
      </BrowserRouter>
    );
  }
}

export default connect(null, actions)(App);

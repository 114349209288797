import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Select,
  message,
  Carousel,
  BackTop,
} from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
//import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import NewCar from "../images/new-car-transparent.png";
import UsedCar from "../images/used-car-transparent.png";
import DemoCar from "../images/demo-car-transparent.png";
import ThinkImg from "../images/man-thinking.png";
import CurrencyFormat from "react-currency-format";
import { connect } from "react-redux";
import {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
} from "../redux/actions";
function onChange(a, b, c) {
  console.log(a, b, c);
}
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const { Option } = Select;
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    ,
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function compareStrings(a, b) {
  a = a;
  b = b;

  return a < b ? -1 : a > b ? 1 : 0;
}
const { Meta } = Card;
const onClickBranch = ({ key }) => {
  message.info(`Website Coming Soon!`);
};
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInWishList: false,
      stock: props.stock,
      isHovered: false,
      stock: [],
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  ToggleCarWishlist(event) {
    var stock = JSON.parse(event.target.id);
    if (!this.state.isInWishList) {
      this.props.addToWishList(stock);
      this.setState({ isInWishList: true });
    } else {
      this.props.removeFromWishList(stock);
      this.setState({ isInWishList: false });
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    window.updateCarousel();
    window.reRenderRangeSlider();
    var stock = this.props.stock;
    fetch("https://www.vcsappcloud.com/mtgroup/api/stock/findallstockclient", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(async (res) => {
      const result = await res.json();
      this.setState({
        stock: result.allStock,
      });
    });
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async (res) => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
    //if (
    //  this.props.wishlistproducts.filter(function (e) {
    //    return e._id === stock._id;
    //  }).length === 1
    //) {
    //  this.setState({ isInWishList: true });
    //}
  }
  mouseHover(event) {
    this.setState({ isHovered: true });
  }

  mouseUnHover(event) {
    this.setState({ isHovered: false });
  }

  render(props, stock) {
    //var heartStyle = {};
    //if (!this.state.isHovered) {
    //  heartStyle = {
    //    color: "#05141f",
    //    fontWeight: "bold",
    //  };
    //}
    const duplicateCheck = [];
    return (
      <div>
        <div className="wrap-body-inner desktop-view" id="NewVehicles">
          <div></div>
          <div className="product product-grid product-grid-2 stock m-t-lg-20 p-t-sm-35 m-b-lg-20 ">
            {/* <div className='heading'>
              <h3>Promotions</h3>
            </div> */}
            <div className="row">
              <Slider {...settings}>
                {this.state.stock.map((stock, index, props) => {
                  return stock.webVisible &&
                    stock.homeVisible &&
                    stock.megaDealers === "KIA" ? (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        <ul className="absolute-caption">
                          <li
                            onClick={() => this.props.fetchSingleStock(stock)}
                          >
                            <h4
                              className="product-name"
                              style={{ marginBottom: "0em", marginTop: "-1em" }}
                            >
                              <Link
                                onClick={() =>
                                  this.props.fetchSingleStock(stock)
                                }
                                to={`/stocko`}
                              >
                                <span
                                  className="f-20  m-t-lg-0 mn-cl"
                                  style={{
                                    display: "block",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {stock.series}
                                  <br />
                                </span>
                                {/* <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>

                                  <CurrencyFormat
                                    value={stock.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"From R"}
                                    //format='R### ### ### ###'
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </span> */}
                              </Link>
                            </h4>
                          </li>
                        </ul>

                        <Link
                          className="product-img"
                          onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/stocko`}
                        >
                          {stock.frontLeftImage ? (
                            <img
                              style={{ objectFit: "contain" }}
                              src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.frontLeftImage}`}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ objectFit: "contain" }}
                              src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                              alt=""
                            />
                          )}
                        </Link>
                        <div style={{ textAlign: "center" }}>
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <Link
                              onClick={() => this.props.fetchSingleStock(stock)}
                              to={`/stocko`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                }}
                              >
                                {stock.series}
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name"></div>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </Slider>
            </div>
          </div>
          <div
            style={{
              height: "14em",
              padding: 0,
              marginBottom: "-4.5em",
              marginTop: "2em",
            }}
          >
            <div
            // style={{ backgroundColor: "rgb(242, 244, 244)" }}
            >
              <Row
                justify="center"
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                  paddingTop: "2em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={12}>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "40px",
                      lineHeight: "0.2em",
                    }}
                  >
                    Kia Mahikeng
                  </h1>
                </Col>
                <Col span={12}>
                  <p
                    style={{
                      textAlign: "left",
                      color: "black",
                      fontSize: "15px",
                      lineHeight: "1.5em",
                    }}
                  >
                    Kia Mahikeng vehicles stand out for their corporate
                    identity, emotional and striking design, while maintaining
                    the main advantage - an excellent ratio of quality and
                    functionality.
                  </p>
                  <br />
                  <Link to={"/contactUs"}>
                    <p
                      style={{
                        textAlign: "left",
                        color: "black",
                        fontSize: "14px",
                        lineHeight: "1.5em",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Get in Touch{" "}
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </p>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
          <Row
            justify="center"
            style={{
              margin: "auto",
              marginTop: "-8vw",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
              alt="logo"
              style={{ width: "100vw" }}
            />
          </Row>

          <div
            //className='banner-item-footer banner-bg-4 color-inher desktop-view'
            style={{
              // height: "14em",
              padding: 0,
            }}
          >
            <div style={{ backgroundColor: "#1F2C35" }}>
              <Row
                justify="center"
                style={{
                  margin: "auto",
                }}
              >
                <Col span={1}></Col>
                <Col
                  span={11}
                  style={{
                    marginTop: "0.5em",
                    paddingTop: "3em",
                    paddingBottom: "3em",
                  }}
                >
                  <h1
                    style={{
                      textAlign: "left",
                      fontSize: "30px",
                      lineHeight: "0.2em",
                      color: "white",
                    }}
                  >
                    NEED MORE ASSISTANCE?
                  </h1>
                  <p
                    style={{
                      textAlign: "left",
                      color: "white",
                      fontSize: "15px",
                      lineHeight: "1em",
                    }}
                  >
                    Get in Touch with us today
                  </p>
                </Col>
                <Col
                  span={4}
                  style={{
                    paddingTop: "2.5em",
                    paddingBottom: "3em",
                  }}
                >
                  <Link
                    to={"/contactUs"}
                    className="ht-btn ht-btn-default m-t-lg-20 p-l-lg-40 p-r-lg-40"
                    style={{ padding: "20px" }}
                  >
                    Get in Touch
                  </Link>
                </Col>
                <Col
                  span={8}
                  style={{
                    textAlign: "center",
                    paddingTop: "3em",
                    paddingBottom: "3em",
                    background: "#37434d",
                  }}
                >
                  <ul
                    className="social-icon list-inline "
                    style={{ marginTop: "-0.3em" }}
                  >
                    <li style={{ marginRight: "1em" }}>
                      <a
                        className="social-icon"
                        href="https://www.facebook.com/KiaMahikeng/"
                        target="_blank"
                        style={{
                          backgroundColor: "#1F2C35",
                          display: "block",
                          paddingTop: "1em",
                          paddingBottom: "0.8em",
                          paddingLeft: "1.4em",
                          paddingRight: "1.4em",
                          borderRadius: "3em",
                        }}
                      >
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          className="fa fa-facebook"
                        />
                      </a>
                    </li>
                    <li style={{ marginRight: "1em" }}>
                      <a
                        href="https://twitter.com/Kia"
                        target="_blank"
                        style={{
                          backgroundColor: "#1F2C35",
                          display: "block",
                          paddingTop: "1em",
                          paddingBottom: "0.8em",
                          paddingLeft: "1.2em",
                          paddingRight: "1.2em",
                          borderRadius: "3em",
                        }}
                      >
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          className="fa fa-twitter"
                        />
                      </a>
                    </li>
                    <li style={{ marginRight: "1em" }}>
                      <a
                        href="https://www.youtube.com/user/KiaMotorsAmerica"
                        target="_blank"
                        style={{
                          backgroundColor: "#1F2C35",
                          display: "block",
                          paddingTop: "1em",
                          paddingBottom: "0.8em",
                          paddingLeft: "1.2em",
                          paddingRight: "1.2em",
                          borderRadius: "3em",
                        }}
                      >
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          className="fa fa-youtube-play"
                        />
                      </a>
                    </li>
                    <li style={{ marginRight: "1em" }}>
                      <a
                        href="https://www.linkedin.com/company/kia-motors/"
                        target="_blank"
                        style={{
                          backgroundColor: "#1F2C35",
                          display: "block",
                          paddingTop: "1em",
                          paddingBottom: "0.8em",
                          paddingLeft: "1.2em",
                          paddingRight: "1.2em",
                          borderRadius: "3em",
                        }}
                      >
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          className="fa fa-linkedin"
                        />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>{" "}
        <div className="wrap-body-inner mobile-view">
          <div></div>
          <div className="product product-grid product-grid-2 stock m-t-lg-20 p-t-sm-35 m-b-lg-20 ">
            {/* <div className='heading'>
               <h3>Promotions</h3>
             </div> */}
            <div className="row">
              <Slider {...settings}>
                {this.state.stock.map((stock, index, props) => {
                  return stock.webVisible &&
                    stock.homeVisible &&
                    stock.megaDealers === "KIA" ? (
                    <div className="col-lg-12" key={index}>
                      <div className="product-item hover-img ">
                        <ul className="absolute-caption">
                          <li
                            onClick={() => this.props.fetchSingleStock(stock)}
                          >
                            <h4
                              className="product-name"
                              style={{ marginBottom: "0em", marginTop: "-1em" }}
                            >
                              <Link
                                onClick={() =>
                                  this.props.fetchSingleStock(stock)
                                }
                                to={`/stocko`}
                              >
                                <span
                                  className="f-20  m-t-lg-0 mn-cl"
                                  style={{
                                    display: "block",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {stock.series}
                                  <br />
                                </span>
                                {/* <span
                                  className="f-15  m-t-lg-0 mn-cl"
                                  style={{
                                    display: "block",
                                    textTransform: "none",
                                    letterSpacing: 0.3,
                                  }}
                                >
                                  <CurrencyFormat
                                    value={stock.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"From R"}
                                    //format='R### ### ### ###'
                                    renderText={(value) => <div>{value}</div>}
                                  />
                                </span> */}
                              </Link>
                            </h4>
                          </li>
                        </ul>

                        <Link
                          className="product-img"
                          onClick={() => this.props.fetchSingleStock(stock)}
                          to={`/stocko`}
                        >
                          {stock.frontLeftImage ? (
                            <img
                              style={{ objectFit: "contain" }}
                              src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${stock.frontLeftImage}`}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ objectFit: "contain" }}
                              src={`${process.env.PUBLIC_URL}/images/coming-soon.png`}
                              alt=""
                            />
                          )}
                        </Link>
                        <div style={{ textAlign: "center" }}>
                          <h4
                            className="product-name"
                            style={{ marginBottom: "0em", marginTop: "-1em" }}
                          >
                            <Link
                              onClick={() => this.props.fetchSingleStock(stock)}
                              to={`/stocko`}
                            >
                              <span
                                style={{
                                  display: "block",
                                  textTransform: "uppercase",
                                }}
                              >
                                {stock.series}
                              </span>
                            </Link>
                          </h4>
                          <div className="product-name"></div>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </Slider>
            </div>
          </div>
          <div
            style={{
              height: "14em",
              padding: 0,
              marginBottom: "-4.5em",
              marginTop: "2em",
            }}
          >
            <div
            // style={{ backgroundColor: "rgb(242, 244, 244)" }}
            >
              <Row
                justify="center"
                style={{
                  maxWidth: "90em",
                  margin: "auto",
                  paddingTop: "2em",
                  paddingBottom: "3em",
                }}
              >
                <Col span={24}>
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "35px",
                      lineHeight: "0.2em",
                    }}
                  >
                    Kia Mahikeng
                  </h1>
                </Col>
                <Col span={24}>
                  <p
                    style={{
                      textAlign: "center",
                      padding: "1em",
                      color: "black",
                      fontSize: "15px",
                      lineHeight: "1.5em",
                    }}
                  >
                    Kia Mahikeng vehicles stand out for their corporate
                    identity, emotional and striking design, while maintaining
                    the main advantage - an excellent ratio of quality and
                    functionality.
                  </p>
                  <br />
                  <Link to={"/contactUs"}>
                    <p
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                        lineHeight: "1.5em",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Get in Touch{" "}
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </p>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
          <Row
            justify="center"
            style={{
              margin: "auto",
              marginTop: "11em",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
              alt="logo"
              style={{ width: "100vw" }}
            />
          </Row>

          <div
            //className='banner-item-footer banner-bg-4 color-inher desktop-view'
            style={{
              // height: "14em",
              padding: 0,
            }}
          >
            <div style={{ backgroundColor: "#1F2C35" }}>
              <Row
                justify="center"
                style={{
                  margin: "auto",
                }}
              >
                <Col
                  span={16}
                  style={{
                    textAlign: "center",
                    marginTop: "0.5em",

                    paddingTop: "3em",
                    paddingBottom: "3em",
                  }}
                >
                  <h1
                    style={{
                      textAlign: "center",
                      fontSize: "30px",
                      lineHeight: "1.2em",
                      color: "white",
                    }}
                  >
                    NEED MORE ASSISTANCE?
                  </h1>
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "15px",
                      lineHeight: "1em",
                    }}
                  >
                    Get in Touch with us today
                  </p>
                  <Link
                    to={"/contactUs"}
                    className="ht-btn ht-btn-default m-t-lg-20 p-l-lg-40 p-r-lg-40"
                    style={{ padding: "20px" }}
                  >
                    Get in Touch
                  </Link>
                </Col>

                <Col
                  span={8}
                  style={{
                    textAlign: "center",
                    padding: "1em",
                    paddingTop: "3em",
                    paddingBottom: "3em",
                    background: "#37434d",
                  }}
                >
                  <ul
                    className="social-icon list-inline"
                    style={{ marginTop: "-0.3em" }}
                  >
                    <li style={{ padding: "0.3em" }}>
                      <a
                        href="https://www.facebook.com/KiaMahikeng/"
                        target="_blank"
                        style={{
                          backgroundColor: "#1F2C35",
                          display: "block",
                          paddingTop: "1em",
                          paddingBottom: "0.8em",
                          paddingLeft: "1.4em",
                          paddingRight: "1.4em",
                          borderRadius: "3em",
                        }}
                      >
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          className="fa fa-facebook"
                        />
                      </a>
                    </li>
                    <li style={{ padding: "0.3em" }}>
                      <a
                        href="https://twitter.com/Kia"
                        target="_blank"
                        style={{
                          backgroundColor: "#1F2C35",
                          display: "block",
                          paddingTop: "1em",
                          paddingBottom: "0.8em",
                          paddingLeft: "1.2em",
                          paddingRight: "1.2em",
                          borderRadius: "3em",
                        }}
                      >
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          className="fa fa-twitter"
                        />
                      </a>
                    </li>
                    <li style={{ padding: "0.3em" }}>
                      <a
                        href="https://www.youtube.com/user/KiaMotorsAmerica"
                        target="_blank"
                        style={{
                          backgroundColor: "#1F2C35",
                          display: "block",
                          paddingTop: "1em",
                          paddingBottom: "0.8em",
                          paddingLeft: "1.2em",
                          paddingRight: "1.2em",
                          borderRadius: "3em",
                        }}
                      >
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          className="fa fa-youtube-play"
                        />
                      </a>
                    </li>
                    <li style={{ padding: "0.3em" }}>
                      <a
                        href="https://www.linkedin.com/company/kia-motors/"
                        target="_blank"
                        style={{
                          backgroundColor: "#1F2C35",
                          display: "block",
                          paddingTop: "1em",
                          paddingBottom: "0.8em",
                          paddingLeft: "1.2em",
                          paddingRight: "1.2em",
                          borderRadius: "3em",
                        }}
                      >
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          className="fa fa-linkedin"
                        />
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <BackTop />
      </div>
    );
  }
}

function mapStateToProps({ wishlistproducts }) {
  return { wishlistproducts };
}

export default connect(mapStateToProps, {
  fetchSingleStock,
  addToWishList,
  removeFromWishList,
})(Home);

import React, { Component, useState } from "react";
import { Link } from "react-router-dom";


import kiaPromo from './../videos/ca_kia_2243_HP-Sizzle-Q422_C_v02_H264-10MB.mp4';

import {
  Checkbox,
  Select,
  Divider,
  Slider,
  Switch,
  Collapse,
  Button,
  Popover,
  Row,
  Col
} from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class KiaRange extends Component {

  render() {
    return (
      <section className='m-t-lg-30 m-t-xs-0' style={{ margin: "auto" }}>

        <div style={{ background: "#06141f", height: "5em", width: "100vw", marginTop: window.isPhone ? "4em" : "9.5em", paddingLeft: window.isPhone ? "1em" : "7em" }}>
          <h3 className='f-weight-600 f-30' style={{ paddingTop: "0.6em", color: "white", maxWidth: "90em", margin: "auto" }}>
            Explore Range</h3></div>


        <div
          style={{
            background: "white",
            padding: 0,

          }}
        >
          <div
          // style={{ backgroundColor: "rgb(242, 244, 244)" }}
          >
            <Row
              justify='center'
              style={{
                margin: "auto",
              }}
            >
              <Col span={24}>
                <div className="video-overlay-1">
                </div>
                <Row
                  style={{
                    maxWidth: "100em",
                    margin: "auto",
                    textAlign: "left",
                    color: "white",
                    padding: "0em",
                    zIndex: 9,
                    position: "absolute",
                    left: 0, right: 0,
                    marginTop: "6em"
                  }}
                >
                  <Col
                    span={24}
                    style={{
                      padding: "3.5em",
                      paddingTop: "2.5em",
                      paddingBottom: "0.5em",
                      textAlign: "left",
                    }}
                  >

                    <h3 className='f-weight-500' style={{ marginTop: "-4em", color: "white", textTransform: "none" }}>
                      DISCOVER KIA<br />
                      <span style={{ fontSize: window.isPhone ? "48px" : "70px", lineHeight: "0.9em" }}>
                        Movement <br />that inspires</span>
                    </h3>
                  </Col>

                </Row>
                <video style={{ width: "100vw", height: window.isPhone ? "16em" : "20em", objectFit: "cover" }}
                  autoPlay
                  muted
                  loop
                >
                  <source src={kiaPromo} type="video/mp4"></source>
                </video>
              </Col>
              {/* <Col span={12}>
              </Col>
              <img
                src={`${process.env.PUBLIC_URL}/images/mahikeng-cars-row.png`}
                alt='logo'
                style={{ width: "50em", position: "absolute", right: 0, marginTop: "-5em" }}
              /> */}
            </Row>
          </div>
        </div>
        <div style={{ margin: "auto", marginTop: "0em", padding: window.isPhone ? "" : "3em", paddingTop: "1em" }}>

          <div className='col-sm-12 col-md-12 col-lg-12' style={{ marginBottom: "4em", marginTop: "2em" }}>

            <div className='product product-grid product-grid-2 stock'>
              <div className='clearfix'></div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/picanto`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            Picanto<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/picanto`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/picanto-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/picanto`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          PICANTO

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/pegas`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            Pegas<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/pegas`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/pegas-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/pegas`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          PEGAS

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/rio`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            RIO<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/rio`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/rio-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/rio`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          Rio

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/sonet`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            SONET<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/sonet`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/sonet-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/sonet`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          Sonet

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/seltos`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            SELTOS<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/seltos`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/seltos-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/sonet`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          Seltos

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/sportage`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            SPORTAGE<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/sportage`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/sportage-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/sportage`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          Sportage

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/sorento`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            SORENTO<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/sorento`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/sorento-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/sorento`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          Sorento

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/carnival`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            CARNIVAL<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/carnival`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/carnival-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/carnival`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          Carnival

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/k2700`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            K2700<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/k2700`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/k2700-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/k2700`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          K2700

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-3' >
                <div className='product-item hover-img '>
                  <ul className='absolute-caption'>
                    <li >
                      <h4
                        className='product-name'
                        style={{ marginBottom: "0em", marginTop: "-1em" }}
                      >
                        <Link

                          to={`/k2500`}
                        >
                          <span className='f-20  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "uppercase", }}>
                            K2500<br />
                          </span>
                          <span className='f-15  m-t-lg-0 mn-cl' style={{ display: "block", textTransform: "none", letterSpacing: 0.3 }}>
                            Book a Test Drive
                            {/* <CurrencyFormat
                          value={stock.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"From R"}
                          //format='R### ### ### ###'
                          renderText={(value) => <div>{value}</div>}
                        /> */}
                          </span>
                        </Link>
                      </h4>
                    </li>
                  </ul>
                  <Link
                    className='product-img'
                    to={`/k2500`}
                  >
                    <img
                      style={{ objectFit: "cover", width: "20em", height: "12em" }}
                      src={`${process.env.PUBLIC_URL}/images/k2500-main.png`}

                      alt=''
                    />
                  </Link>
                  <div style={{ textAlign: "center" }}>
                    <h4
                      className='product-name'
                      style={{ marginBottom: "0em", marginTop: "-1em" }}
                    >
                      <Link
                        to={`/k2500`}
                      >
                        <span style={{ display: "block", textTransform: "uppercase", }}>
                          K2500

                        </span>
                      </Link>
                    </h4>
                    <div className='product-name'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}
export default KiaRange;

import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Carousel, Row } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";
import HeaderAlternative from "./HeaderAlternative";

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "bottom",
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
    };
  }
  componentDidMount() {
    fetch(
      "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    ).then(async res => {
      const result = await res.json();
      this.setState({
        pexUnits: result.allPexUnit,
      });
    });
  }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  renderSearchBar() {
    const { dotPosition } = this.state;
    const { pexUnits } = this.props;
    return (
      <div>
        <section className="block-sl desktop-view">
          <div className="container-custom">
            <div className="row">
              <div>
                <Carousel
                  autoplay
                  effect="fade"
                  dotPosition={dotPosition}
                  dots={true}
                  style={{
                    // width: "100vw",

                    // position: "absolute",
                    left: 0,
                    objectFit: "cover",
                  }}
                >
                  {" "}
                  {this.state.pexUnits.map(pexUnits => {
                    return pexUnits.homeImage &&
                      pexUnits.pageName === "Kia Controller" ? (
                      <span>
                        <div className="overlay-black "></div>
                        <div className="overlay">
                          <img
                            className="image-zoom"
                            style={{}}
                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage}`}
                            alt="logo"
                          />
                        </div>
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map(pexUnits => {
                    return pexUnits.homeImage2 &&
                      pexUnits.pageName === "Kia Controller" ? (
                      <span>
                        {" "}
                        <div className="overlay-black "></div>
                        <div className="overlay">
                          <img
                            className="image-zoom"
                            style={{}}
                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage2}`}
                            alt="logo"
                          />{" "}
                        </div>
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map(pexUnits => {
                    return pexUnits.homeImage3 &&
                      pexUnits.pageName === "Kia Controller" ? (
                      <span>
                        {" "}
                        <div className="overlay-black "></div>
                        <div className="overlay">
                          <img
                            className="image-zoom"
                            style={{}}
                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage3}`}
                            alt="logo"
                          />{" "}
                        </div>
                      </span>
                    ) : null;
                  })}
                </Carousel>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-12"
                style={{
                  zIndex: 10,
                  textAlign: "center",
                  width: "100vw",
                  position: "absolute",
                  left: 0,
                  objectFit: "contain",

                  top: "32vw",

                  left: 0,
                  right: 0,
                }}
              >
                <div className="banner-item-home banner-2x-home no-bg color-inher-home">
                  <h2
                    className="f-weight-500"
                    style={{
                      fontFamily: "Kia Medium",
                      fontSize: "40px",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      color: "white",
                      marginBottom: "-0em",
                      textTransform: "none",
                    }}
                  >
                    <br />
                    Movement that inspires
                  </h2>
                  <p
                    style={{
                      marginBottom: "-0.5em",
                      marginTop: "0em",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      color: "white",
                    }}
                  >
                    DISCOVER KIA
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <Link
                      to={"/kia-range"}
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{ textTransform: "none" }}
                    >
                      EXPLORE VEHICLES
                    </Link>
                    <Link
                      to="/ContactUs"
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{
                        textTransform: "none",
                        marginLeft: "1em",
                      }}
                    >
                      GET IN TOUCH
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{" "}
        <section className="block-sl mobile-view" style={{ width: "100vw" }}>
          <div className="container-custom">
            <div className="row" style={{ marginTop: "4.3em" }}>
              <div style={{ backgroundColor: "black" }}>
                <Carousel
                  autoplay
                  effect="fade"
                  dotPosition={dotPosition}
                  dots={true}
                  style={{
                    left: 0,
                    objectFit: "cover",
                  }}
                >
                  {" "}
                  {this.state.pexUnits.map(pexUnits => {
                    return pexUnits.homeImage &&
                      pexUnits.pageName === "Kia Controller" ? (
                      <span>
                        <div className="overlay-black"></div>
                        <div className="overlay">
                          <img
                            className="image-zoom"
                            style={{}}
                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage}`}
                            alt="logo"
                          />
                        </div>
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map(pexUnits => {
                    return pexUnits.homeImage2 &&
                      pexUnits.pageName === "Kia Controller" ? (
                      <span>
                        {" "}
                        <div className="overlay-black "></div>
                        <div className="overlay">
                          <img
                            className="image-zoom"
                            style={{}}
                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage2}`}
                            alt="logo"
                          />{" "}
                        </div>
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map(pexUnits => {
                    return pexUnits.homeImage3 &&
                      pexUnits.pageName === "Kia Controller" ? (
                      <span>
                        {" "}
                        <div className="overlay-black "></div>
                        <div className="overlay">
                          <img
                            className="image-zoom"
                            style={{}}
                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage3}`}
                            alt="logo"
                          />{" "}
                        </div>
                      </span>
                    ) : null;
                  })}
                </Carousel>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-12"
                style={{
                  zIndex: 10,
                  textAlign: "center",
                  width: "100vw",
                  position: "absolute",
                  left: 0,
                  objectFit: "contain",

                  top: "25vh",

                  left: 0,
                  right: 0,
                }}
              >
                <div className="banner-item-home banner-2x-home no-bg color-inher-home">
                  <h2
                    className="f-weight-500"
                    style={{
                      fontFamily: "Kia Medium",
                      fontSize: "27px",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      color: "white",
                      marginBottom: "-0em",
                      textTransform: "none",
                    }}
                  >
                    <br />
                    Movement that inspires
                  </h2>
                  <p
                    style={{
                      marginBottom: "-0.5em",
                      marginTop: "0em",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      color: "white",
                    }}
                  >
                    DISCOVER KIA
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <Link
                      to={"/kia-range"}
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{ textTransform: "none" }}
                    >
                      EXPLORE VEHICLES
                    </Link>
                    {/* <Link
                      to='/ContactUs'
                      className='ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30'
                      style={{
                        fontSize: "10px",
                        textTransform: "none",
                        marginLeft: "1em",

                      }}
                    >
                      GET IN TOUCH
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <SearchBarHome filters={this.getFilters.bind(this)} /> */}
      </div>
    );
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  render() {
    const { location } = this.props;
    return (
      <div
        id="wrap"
        // className='color1-inher'
      >
        {location.pathname === "/home" || location.pathname === "/" ? (
          <Header />
        ) : (
          <HeaderAlternative />
        )}

        {location.pathname === "/home" || location.pathname === "/"
          ? this.renderSearchBar()
          : null}
        <div id="wrap-body" className="p-t-lg-45">
          <div className="container-custom">{this.props.children}</div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(SharedLayout);
